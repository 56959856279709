import React from "react"
import styled, { css } from "styled-components"
import wavePath from "../data/images/wave.svg"
import { addResponsivity, up, down, between } from "../lib/styles"
import smallWavePath from "../data/images/wave-small.svg"

const StyledImg = styled.img`
  ${addResponsivity()}
`
const WaveImage = ({ small, ...rest }) => (
  <StyledImg {...rest} src={small ? smallWavePath : wavePath} alt="" />
)

WaveImage.defaultProps = {}

WaveImage.propTypes = {}

export default WaveImage
