import React from "react"
import styled, { css } from "styled-components"
import { addResponsivity, up, down, between } from "../lib/styles"

const DashedLine = styled.div`
  ${({ width = "100%", height = "100%", isVertical = false }) => css`
    display: block;
    background-image: linear-gradient(
      to right,
      #b2e8e4 7px,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: bottom left;
    background-size: 11px 100px;
    background-repeat: repeat-x;
    width: ${width};
    height: 2px;
    ${isVertical &&
    css`
      width: 2px;
      height: ${height};
      background-image: linear-gradient(
        to bottom,
        #b2e8e4 7px,
        rgba(255, 255, 255, 0) 0%
      );
      background-position: top left;
      background-size: 100px 11px;
      background-repeat: repeat-y;
    `}
  `}
`
DashedLine.defaultProps = {}

DashedLine.propTypes = {}

export default DashedLine
