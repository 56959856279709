import React from "react"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"
import Text from "./Text"
import Row from "./Row"
import Col from "./Col"
import CallMeForm from "./CallMeForm"
import Title from "./Title"
import Gap from "./Gap"
import T from "../lib/intl/T"
import { up } from "../lib/styles"

import bg from "../data/images/sticky-form-bg.svg"
import callMePhoto from "../data/images/call-me-photo.png"
import closePopupIconSrc from "../data/images/sticky-form-close.svg"

const Wrapper = styled.div`
  width: 408px;
  height: 280px;

  position: relative;
  bottom: -1px;
  float: right;
  z-index: 100;

  padding: 62px 48px 25px;
  display: none;

  pointer-events: auto;

  ${up("mobile")} {
    display: block;
    right: 50px;
  }
  ${up("menu")} {
    right: 150px;
  }
`

const BgImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`

const ContentWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const WhiteText = styled(Text)`
  color: #ffffff;
`

const WhiteTitle = styled(Title.SmallerTitle)`
  color: #ffffff;
`

const Close = styled.div`
  position: absolute;
  padding: 10px;
  top: 55px;
  right: 38px;
  cursor: pointer;
  z-index: 500;
`

const HIDE_STICKY = "HIDE_STICKY"

const ThanksMessage = () => (
  <WhiteText>
    <T raw>{"Děkujeme,<br />ozveme se vám obratem."}</T>
  </WhiteText>
)

class StickyForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isHidden: true,
    }
  }

  componentDidMount() {
    const isHidden = sessionStorage.getItem(HIDE_STICKY)
    this.setState({ isHidden })
  }

  close = () => {
    this.setState({ isHidden: true })
    sessionStorage.setItem(HIDE_STICKY, true)
  }

  render() {
    const { isHidden } = this.state
    if (isHidden) return null
    return (
      <Wrapper>
        <BgImage src={bg} />
        <Close onClick={this.close}>
          <img src={closePopupIconSrc} alt="close icon" />
        </Close>
        <ContentWrapper>
          <Row>
            <img src={callMePhoto} alt="" />
            <Gap gap="16px" />
            <Col justifyContent="center">
              <WhiteTitle>
                <T>Zavolám vám</T>
              </WhiteTitle>
              <WhiteText>Kristýna Hantschová</WhiteText>
            </Col>
          </Row>
          <Gap gap="8px" />
          <CallMeForm thanks={<ThanksMessage />} isSticky>
            <WhiteText>
              <T>
                Zadejte telefonní číslo v mezinárodním formátu a ozvu se vám.
              </T>
            </WhiteText>
            <Gap gap="8px" />
          </CallMeForm>
        </ContentWrapper>
      </Wrapper>
    )
  }
}

export default StickyForm
