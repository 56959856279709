import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import Title from './Title'
import Text from './Text'
import Gap from './Gap'
import Row from './Row'
import Col from './Col'
import { up, addPagePadding, addFullWidth } from '../lib/styles'
import { PHONE_NUMBER, PHONE_NUMBER_EN, PHONE_NUMBER_EN_PREFIX } from '../lib/config'
import T from '../lib/intl/T'

import bgPath from '../data/images/hp-top-background.svg'
import phoneIconPath from '../data/images/hp-phone-icon.svg'

const bp = 'menu'

const Wrapper = styled.div`
  /* background-image: url(${bgPath}); */
  position: relative;
  ${addFullWidth()}
  ${addPagePadding()}
`
const BgImage = styled.img`
  position: absolute;
  z-index: -1;

  bottom: 0;
  left: 50%;
  transform: translateX(-27%);

  ${up('mobile')} {
    transform: translateX(-36%);
  }

  ${up(bp)} {
    width: 100%;
    left: 0;
    transform: none;
  }
`

const ContentWrapper = styled.div`
  ${({ theme: { sizes } }) => css`
    width: 100%;
    /* max-width: ${sizes.pageWidth}px; */
    max-width: 1280px;
    display: flex;
    margin: auto;
    flex-direction: column;
    align-content: stretch;
    /* ${addPagePadding()} */
  `}
`

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  top: 40px;
  max-width: 360px;

  ${up(bp)} {
    max-width: none;
    position: absolute;
    top: -30px;
  }
`

const ImageCol = styled(Col)`
  position: relative;
`

const Image = styled(GatsbyImage)``

const PhoneText = styled.div`
  ${({ theme: { colors, fonts } }) => css`
    color: ${colors.green};
    font-family: ${fonts.signika};
    font-size: 32px;
    font-weight: 700;
    line-height: 32px;
  `}
`

const getTitleProps = language => {
  const result = {
    maxWidth: '500px',
    mobileMaxWidth: '300px',
    fontSize: '70px',
    lineHeight: '78px',
  }
  if (language === 'de') {
    result.maxWidth = '624px'
    result.mobileMaxWidth = '382px'
    result.fontSize = '56px'
    result.lineHeight = '60px'
    result.mobileFontSize = '30px'
    result.mobileLineHeight = '38px'
  }
  if (language === 'en') {
    result.maxWidth = '605px'
    result.mobileMaxWidth = '346px'
    result.mobileFontSize = '38px'
    result.mobileLineHeight = '46px'
  }

  return result
}

const getPhoneNumber = language =>
  // language === "en" ? PHONE_NUMBER_EN : PHONE_NUMBER
  PHONE_NUMBER

const getPhonePrefix = language =>
  // language === "en" ? PHONE_NUMBER_EN_PREFIX : "+420"
  '+420'

const HpTop = ({ image, language }) => (
  <Wrapper>
    <Gap gap="97px" mobileGap="24px" bp={bp} />

    <BgImage src={bgPath} alt="" />
    <ContentWrapper>
      <Row responsive bp={bp}>
        <Gap gap="70px" mobileGap="0" bp={bp} />

        <Col>
          <Title.PageTitle {...getTitleProps(language)}>
            <T raw>Klinika pro lidi</T>
          </Title.PageTitle>
          <Gap gap="16px" />
          <Text.Intro maxWidth="456px">
            <T>
              Používáme jen ověřené chirurgické postupy, za které můžeme ručit. Proto ať už Vás k
              nám přivedou na plastiku vrozené dispozice, věk, nebo úraz, jsme připraveni Vám
              pomoci.
            </T>
          </Text.Intro>
          <Gap gap="16px" mobileGap="28px" bp={bp} />
          <Col alignItems="flex-start">
            <a href={`tel:${getPhonePrefix(language)}${getPhoneNumber(language)}`}>
              <Row alignItems="center">
                <img src={phoneIconPath} alt="" />
                <Gap gap="16px" />
                <PhoneText>
                  {language !== 'cs' && `${getPhonePrefix(language)} `}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: getPhoneNumber(language).split(' ').join('&nbsp;'),
                    }}
                  />
                </PhoneText>
              </Row>
            </a>
          </Col>
          <Gap gap="125px" showAfter={bp} />
        </Col>
        <Gap gap="62px" mobileGap="0" bp={bp} />
        <ImageCol grow={1}>
          <ImageWrapper>
            <Image image={image.image.gatsbyImageData} />
          </ImageWrapper>
        </ImageCol>
      </Row>
    </ContentWrapper>
  </Wrapper>
)

HpTop.defaultProps = {}

HpTop.propTypes = {
  image: PropTypes.object.isRequired,
}

export default HpTop
