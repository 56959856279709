import React from "react"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"
import { up, addFullWidth } from "../lib/styles"
import ContactForm from "./ContactForm"
import Col from "./Col"
import Row from "./Row"
import Title from "./Title"
import Gap from "./Gap"
import Text from "./Text"
import WaveImage from "./WaveImage"
import HexaSection from "./HexaSection"
import footerBackgroundPath from "../data/images/pre-footer-background.svg"
import backgroundPath from "../data/images/blue-hexa-background.svg"
import T from "../lib/intl/T"

// const FooterWrapper = styled.div`
//   ${({ theme: { pageSidePadding } }) => css`
//     padding-top: 100px;
//     ${up('menu')} {
//       padding-top: 120px;
//     }

//     ${addFullWidth()}
//   `}
// `

const BackgroundWrapper = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  img {
    display: block;
    width: 100%;
  }
`

const breakpoint = 700

const ContentWrapper = styled.div`
  ${({ theme: { colors, pageSidePadding }, isFooter }) => css`
    background-color: ${colors.lightBlue};
    padding: 0 ${pageSidePadding};
    ${isFooter &&
    css`
      padding-bottom: 40px;
      ${up(breakpoint)} {
        padding-bottom: 80px;
      }
    `}
  `}
`

const FormCol = styled(Col)`
  ${({ theme: { breakpoints } }) => css`
    ${up(breakpoint)} {
      flex-basis: 455px;
    }
  `}
`

const Wrapper = styled(HexaSection).attrs(({ isFooter }) => ({
  paddingBottom: isFooter ? "0" : "52px",
  mobilePaddingBottom: isFooter ? "0" : "100px",
}))``

const ExtraMobileGap = styled(Gap)`
  display: none;
  ${up(420)} {
    display: block;
  }
  ${up("mobile")} {
    display: none;
  }
`

class ContactFormSection extends React.Component {
  state = {
    isSent: false,
  }

  onIsSent = () => {
    this.setState({ isSent: true })
  }

  render() {
    const { isFooter, isHp, ctaName, ...props } = this.props
    const { isSent } = this.state
    const Thanks = (
      <>
        <Gap gap="24px" />
        <Title.MediumTitle>
          <T>Děkujeme!</T>
        </Title.MediumTitle>
        <Gap gap="16px" />
        <Text>
          <T>Obratem se vám ozveme a domluvíme se na nezávaznou konzultaci.</T>
        </Text>
      </>
    )
    const NormalContent = (
      <>
        <Title.MediumTitle>
          <T>Máte dotaz nebo se chcete domluvit na konzultaci?</T>
        </Title.MediumTitle>
        <Gap gap="16px" />
        <Text responsive>
          <T raw>contact form - text</T>
          {/* Rozhodnout se pro zákrok není jen tak.
          <br />
          Máte otázky nebo potřebujete druhý názor?
          <br />
          Poraďte se s našimi chirurgy.
          <br />
          Domluvte si nezávaznou konzultaci zdarma. */}
        </Text>
      </>
    )
    const HpContent = (
      <>
        <Title.BiggerTitle>
          <T raw>Online konzultace</T>
        </Title.BiggerTitle>
        <Gap gap="16px" />
        <Text responsive>
          <T raw>contact form - homepage text</T>
          {/* Rozhodnout se pro zákrok není jen tak.
          <br />
          Máte otázky nebo potřebujete druhý názor?
          <br />
          Poraďte se s našimi chirurgy.
          <br />
          Domluvte si nezávaznou konzultaci zdarma. */}
        </Text>
      </>
    )

    const Content = isHp ? HpContent : NormalContent

    return (
      <Wrapper isFooter={isFooter} id="contact-form">
        {isFooter && (
          <BackgroundWrapper>
            <img
              src={isFooter ? footerBackgroundPath : backgroundPath}
              alt=""
            />
          </BackgroundWrapper>
        )}

        <ContentWrapper isFooter={isFooter}>
          <Gap gap="0" mobileGap="10px" />
          <Row
            maxWidth="880px"
            alignItems="center"
            justifyContent="center"
            margin="auto"
          >
            <Text>
              <T raw>
                {
                  "<b>Máme pro vás stále otevřeno.</b> Formé clinic je zdravotnické zařízení. Návštěva kliniky a veškeré zákroky jsou <b>v souladu s aktuálními vládními opatřeními COVID-19 platnými v České republice.</b> V případě cesty z jiného okresu vám vystavíme <b>potvrzení o návštěvě lékaře.</b>"
                }
              </T>
            </Text>
          </Row>
          <Gap gap="30px" showBelow={420} />
          <Gap gap="40px" mobileGap="0" />
          <Row justifyContent="center" responsive bp={breakpoint} wrap="nowrap">
            <Col maxWidth="392px" alignItems="flex-start">
              <ExtraMobileGap gap="30px" />
              <WaveImage />
              <Gap gap="24px" />
              {isSent ? Thanks : Content}
            </Col>
            <Gap gap="34px" />
            <FormCol>
              {/* basis na mobilu způsobuje protažení, ještě dořešit nějak obecně */}
              {!isSent && (
                <ContactForm
                  onIsSent={this.onIsSent}
                  hasPicture={isHp}
                  ctaName={ctaName}
                />
              )}
            </FormCol>
            <ExtraMobileGap gap="30px" />
          </Row>
          {isSent && <Gap gap="40px" mobileGap="0" />}
        </ContentWrapper>
      </Wrapper>
    )
  }
}

ContactFormSection.defaultProps = {
  isFooter: true,
  isHp: false,
}

ContactFormSection.propTypes = {
  isFooter: PropTypes.bool,
  isHp: PropTypes.bool,
}

export default ContactFormSection
