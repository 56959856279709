import React from "react"
import injectIntl from "./injectIntl"

const T = ({ children, args = [], raw, intl: { t, language }, ...props }) => {
  let translated = t(children, language)
  args.forEach((arg, index) => {
    translated = translated.split(`{arg${index + 1}}`).join(arg)
  })
  if (!raw) {
    return <React.Fragment>{translated}</React.Fragment>
  }

  return <span dangerouslySetInnerHTML={{ __html: translated }} />
}

export default injectIntl(T)
