// !UTILS - veci sem patri pouze pokud jsou pro gatsby config / node a zaroven nejakou komponentu

function trimSlash(path) {
  let trimmedPath =
    path[path.length - 1] === "/" ? path.substring(0, path.length - 1) : path
  if (trimmedPath === "") {
    trimmedPath = "/"
  }
  return trimmedPath
}

// has to be old fashion, cuz its used in gatsby node
module.exports = exports = {
  trimSlash,
}
