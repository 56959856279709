// assets
import infoPath1 from "../data/images/procedures/icon-1.svg"
import infoPath2 from "../data/images/procedures/icon-2.svg"
import infoPath3 from "../data/images/procedures/icon-3.svg"
import infoPath4 from "../data/images/procedures/icon-4.svg"

export const PHONE_NUMBER = "224 284 421"

export const PHONE_NUMBER_EN = "7378 821607" // +44
export const PHONE_NUMBER_EN_PREFIX = "+44"
export const PROCEDURES_INFOS = [
  {
    path: infoPath1,
    text: "Při provedení všech zákroků a ošetření dbáme na maximální šetrnost a klademe důraz na bezpečnost pro vaše zdraví.",
  },
  {
    path: infoPath2,
    text: "Používáme nejmodernější přístroje a postupy s garancí vysoké kvality odborné péče profesionálů uznávaných u nás i v zahraničí.",
  },
  {
    path: infoPath3,
    text: "Cena je orientační a může se lišit na základě vašich konkrétních potřeb a rozsahu zákroku. Upřesněna bude po osobní konzultaci s lékařem.",
  },
  {
    path: infoPath4,
    condition: ({ language }) => language === "cs",
    text: "Dopřejte si změnu hned a plaťte pak. Nově nabízíme možnost rozložit si cenu zákroku až do 24 měsíčních plateb bez navýšení.",
  },
]

export const urlBase = process.env.APP_URL || "http://localhost:3000"
