// sites generation and lang settings
// if empty, default config will be used
// this file is used for gatsby sites generations and for link translations in react (e.g. menu lang switching)
// eslint-disable-next-line no-multi-assign
module.exports = exports = {
  PostTemplate: {
    pathKey: "/blog/*", // for finding it from url
    rootKey: "/blog", // for translating the root part
    translateRoot: true,
    fallbackKey: "/blog", // if translation was not found, use fallback key path
    disableAutoRedirect: true, // based on IP when visiting site for the first time
    // onlyPrefix: true, // translate only prefix - e.g. route has slug in it
  },
  ProcedureTemplate: {
    pathKey: "/procedures/*",
    rootKey: "/procedures",
    translateRoot: true,
    // disableAutoRedirect: true,
    // onlyPrefix: true,
  },
  PageTemplate: {
    pathKey: "",
    // disableAutoRedirect: true,
    fallbackKey: "/",
    // onlyPrefix: true,
  },
  PersonTemplate: {
    pathKey: "", // cannot be find based on url, so it's mandatory to pass pageKey in translations (link)
    // disableAutoRedirect: true,
    fallbackKey: "/team",
    // onlyPrefix: true,
  },
  JobOfferTemplate: {
    pathKey: "/career/*",
    rootKey: "/career",
    translateRoot: true,
    disableAutoRedirect: true,
    fallbackKey: "/career",
  },
}
