import React from "react"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import Title from "./Title"
import Text from "./Text"
import Col from "./Col"
import Row from "./Row"
import Gap from "./Gap"
import WaveImage from "./WaveImage"
import Button from "./Button"
import VH from "./VH"
import Link from "./Link"
import T from "../lib/intl/T"

import { up } from "../lib/styles"
import { createAnchor } from "../lib/helpers"

import hexagonPath from "../data/images/orthopedics/hexa-orthopedics-hp.svg"

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
`

const ImagesCol = styled(Col)`
  padding-right: 0;
  margin-top: 0;
  width: 381px;
  ${up("menu")} {
    padding-right: 50px;
    padding-left: 30px;
    width: 100%;

    margin-top: -46px;
  }
`

const NewLabelWrapper = styled.div`
  position: absolute;

  top: 48px;
  left: -5px;

  ${up("menu")} {
    top: 95px;
    left: 33px;
  }

  width: 109px;
  height: 109px;
  div {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  p {
    padding: 0 5px 10px 5px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    text-transform: uppercase;
    text-align: center;
  }
`

const NewLabel = ({ children }) => (
  <NewLabelWrapper>
    <img src={hexagonPath} />
    <div>
      <p>{children}</p>
    </div>
  </NewLabelWrapper>
)

const EpilationSection = ({ image }) => {
  // console.log("TODO")
  return (
    <Row responsive bp="menu" width="100%">
      <Col maxWidth="489px" width="100%" alignItems="flex-start">
        <WaveImage />
        <Gap gap="24px" />
        <Title.BigTitle>
          <T>Trvalá epilace</T>
        </Title.BigTitle>
        <Gap gap="24px" mobileGap="16px" />
        <Text responsive>
          <T>
            Zbavíme vás nežádoucího ochlupení jednou pro vždy. Jako jediní v
            České republice disponujeme revolučním lékařským diodovým laserem
            OPT LED Excelsior 3.0 s unikátní 3D hlavicí, který nám umožňuje
            odstranit i ty nejjemnější a nejsvětlejší chloupky s téměř 100%
            účinností.
          </T>
        </Text>
        <Gap gap="26px" />

        <VH showBelow="menu">
          <ImageWrapper>
            {/* <NewLabel>
              <T>Nově otevřeno</T>
            </NewLabel> */}
            <GatsbyImage image={image.image.gatsbyImageData} />
          </ImageWrapper>
          <Gap gap="20px" />
        </VH>

        <Row maxWidth="318px" width="100%">
          <Button.Link to="#contact-form" responsiveFullWidth bp={840} dashed>
            <T>Chci se objednat</T>
          </Button.Link>
        </Row>
      </Col>
      <VH showAfter="menu">
        <ImagesCol alignItems="center">
          <Col alignItems="center">
            <ImageWrapper>
              {/* <NewLabel>
                <T>Nově otevřeno</T>
              </NewLabel> */}
              <GatsbyImage image={image.imageMobile.gatsbyImageData} />
            </ImageWrapper>
          </Col>
        </ImagesCol>
      </VH>
    </Row>
  )
}

EpilationSection.defaultProps = {}

EpilationSection.propTypes = {
  image: PropTypes.object.isRequired,
  procedureCategories: PropTypes.array.isRequired,
}

export default EpilationSection
