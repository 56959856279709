import React from "react"
import styled, { css } from "styled-components"
import borderImage from "../data/images/button-border.png"
import Link from "./Link"
import { down } from "../lib/styles"

const Button = styled.div`
  ${({
    theme: { fonts, colors },
    padding = "0px 24px",
    height = "48px",
    width,
    responsiveFullWidth,
    bp = "mobile",
    disabled,
  }) => css`
    min-width: 146px;
    /* height: ${height}; */
    /* REVIEW: resil bych bud height nebo padding / line-height ale ne oboji dohromady... s height nefunguje dvojradek */
    display: inline-block;
    border-radius: 12px;
    background-color: #00b3a6;

    color: ${colors.white};
    font-family: ${fonts.muli};
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.5px;
    line-height: ${height};
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
    transition: 0.2s;
    padding: ${padding};
    width: ${width};

    ${disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}

    ${responsiveFullWidth &&
    css`
      ${down(bp)} {
        width: 100%;
      }
    `}

    &:hover {
      box-shadow: 0 8px 16px rgba(0, 96, 86, 0.24);
      background-color: #095e57;
    }
  `}
`

Button.Dashed = styled(Button)`
  ${({ theme: { colors } }) => css`
    background-color: ${colors.white};

    color: ${colors.green};

    border: solid 1px #00b3a6;
    border-image-source: url(${borderImage});

    border-image-slice: 12 10 12 11;
    border-image-width: auto;
    border-image-outset: 0px;
    border-image-repeat: repeat stretch;

    border: 2px dashed rgba(0, 179, 166, 0.75);
    line-height: 46px;

    &:hover {
      background-color: #d6fbf9;
      box-shadow: none;
    }
  `}
`

const ButtonLink = styled(Link)`
  ${({ theme, responsiveFullWidth, bp = "mobile" }) => css`
    ${responsiveFullWidth &&
    css`
      ${down(bp)} {
        width: 100%;
      }
    `}
  `}
`

Button.Link = ({
  dashed,
  responsiveFullWidth,
  buttonProps,
  children,
  bp,
  ...rest
}) => {
  const Component = dashed ? Button.Dashed : Button

  return (
    <ButtonLink
      noStyle
      responsiveFullWidth={responsiveFullWidth}
      bp={bp}
      {...rest}
    >
      <Component
        {...buttonProps}
        responsiveFullWidth={responsiveFullWidth}
        bp={bp}
        children={children}
      />
    </ButtonLink>
  )
}
export default Button
