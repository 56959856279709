import CountUp from "react-countup"
import React from "react"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"
import Col from "./Col"
import Row from "./Row"
import WaveImage from "./WaveImage"
import Gap from "./Gap"
import Title from "./Title"
import Text from "./Text"
import T from "../lib/intl/T"
import { up } from "../lib/styles"

import ourWorkIcon1Path from "../data/images/our-work/1.svg"
import ourWorkIcon2Path from "../data/images/our-work/2.svg"
import ourWorkIcon3Path from "../data/images/our-work/3.svg"
import ourWorkIcon4Path from "../data/images/our-work/4.svg"
import ourWorkIcon5Path from "../data/images/our-work/5.svg"
import ourWorkIcon6Path from "../data/images/our-work/6.svg"

const data = [
  {
    imagePath: ourWorkIcon3Path,
    num: "75 541",
    text: "spokojených klientů",
    settingKey: "happyClientsCount",
  },
  {
    imagePath: ourWorkIcon1Path,
    num: "478",
    text: "zvětšení prsou",
    settingKey: "breastCount",
  },
  {
    imagePath: ourWorkIcon5Path,
    num: "106 589",
    text: "operací celkově",
    settingKey: "operationsCount",
  },
  {
    imagePath: ourWorkIcon6Path,
    num: "4 589",
    text: "kombinovaných operací",
    settingKey: "packagesCount",
  },
]

const bp = 750

const IconWrapper = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;

  justify-content: center;
  ${up(bp)} {
    justify-content: flex-start;
  }
`

const ContentWrapper = styled.div``

const Item = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  ${up(bp)} {
    align-items: flex-start;
    flex-direction: column;
  }
`

class OurWorkSection extends React.Component {
  state = {
    entered: false,
  }

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll)
  }

  onScroll = () => {
    try {
      const pos =
        this.rowRef.getBoundingClientRect().top - window.innerHeight + 200
      if (pos < 0) {
        this.setState({ entered: true })
      }
    } catch (error) {}
  }

  render() {
    const { settings } = this.props
    const { entered } = this.state

    return (
      <Col alignItems="flex-start" width="100%" maxWidth="1010px" margin="auto">
        <WaveImage />
        <Gap gap="24px" />
        <Title.BigTitle>
          <T>Co máme za sebou</T>
        </Title.BigTitle>
        <Gap gap="24px" />
        <Row
          justifyContent="space-between"
          width="100%"
          maxWidth="897px"
          responsive
          bp={bp}
          ref={ref => (this.rowRef = ref)}
        >
          {data.map(({ imagePath, num, text, settingKey }) => (
            <React.Fragment key={imagePath}>
              <Item>
                <IconWrapper>
                  <img src={imagePath} alt="" />
                </IconWrapper>
                <Gap gap="24px" />
                <ContentWrapper>
                  <Title.MediumTitle>
                    <CountUp
                      end={
                        entered
                          ? settings && settings[settingKey]
                            ? settings[settingKey]
                            : num
                          : 0
                      }
                      duration={3}
                    />
                  </Title.MediumTitle>
                  <Text
                    responsive
                    lineHeight="24px"
                    style={{ marginTop: "-4px" }}
                  >
                    <T>{text}</T>
                  </Text>
                </ContentWrapper>
              </Item>
              <Gap gap="40px" hideAfter={bp} />
            </React.Fragment>
          ))}
        </Row>
        <Gap gap="78px" mobileGap="13px" bp={bp} />
      </Col>
    )
  }
}

OurWorkSection.defaultProps = {}

OurWorkSection.propTypes = {
  settings: PropTypes.object.isRequired,
}

export default OurWorkSection
