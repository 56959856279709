// has to be filename of ./pages/*
// the actual route paths are defined in translations

exports.links = {
  HOMEPAGE: "/",
  ABOUT: "/about",
  TEAM: "/team",
  PROCEDURES: "/procedures",
  CHANGES: "/changes",
  REFERENCES: "/references",
  BLOG: "/blog",
  FOUNDATION: "/foundation",
  CONTACT: "/contact",
  FAQ: "/faq",
  CAREER: "/career",
  APARTMENTS: "/apartments",
}
