/**
 * generic component for flex listing items
 * automatically wraps children items to wrapper
 * is responsive - below bp switches to col and disables gaps
 */

import React, { Component } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { addResponsivity, up, down, between } from "../lib/styles"
import { Col, Row, Text } from "."

const Wrapper = styled.div`
  ${({ maxWidth }) => css`
    max-width: ${maxWidth || "100%"};
    width: 100%;
  `}
`

const Container = styled.div`
  ${({ bp, gap }) => css`
    display: flex;
    flex-direction: column;

    ${up(bp)} {
      flex-direction: row;
      flex-wrap: wrap;
      margin-left: -${gap};
    }
  `}
`

const ItemWrapper = styled.div`
  ${({ gap, bp, fluid }) => css`
    ${up(bp)} {
      margin-left: ${gap};
      ${fluid &&
      css`
        flex-basis: 100px;
        flex-grow: 1;
      `}
    }
  `}
`

class Listing extends Component {
  render() {
    const { gap, bp, maxWidth, fluid } = this.props
    let { children } = this.props

    if (!children) {
      return null
    }

    if (!Array.isArray(children)) {
      children = [children]
    }

    return (
      <Wrapper maxWidth={maxWidth}>
        <Container gap={gap} bp={bp}>
          {children.map((item, index) => (
            <ItemWrapper
              key={item.props.innerKey || index}
              gap={gap}
              bp={bp}
              fluid={fluid}
            >
              {item}
            </ItemWrapper>
          ))}
        </Container>
      </Wrapper>
    )
  }
}

Listing.propTypes = {
  bp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxWidth: PropTypes.string,
}

Listing.defaultProps = {
  bp: "mobile",
  gap: "16px",
}

export default Listing
