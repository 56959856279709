import React from 'react'
import styled, { css } from 'styled-components'
import { graphql, Link as GatsbyLink, navigate, useStaticQuery } from 'gatsby'
import { up, addResponsivity } from '../lib/styles'
import { links } from '../lib/links'
import { trimSlash } from '../lib/utils'
import injectIntl from '../lib/intl/injectIntl'
import { findKey, translatePath, prefixPath } from '../lib/intl'

const LinkWrapper = styled.div`
  ${({
    theme: { colors },
    upperCase,
    white,
    red,
    blue,
    green,
    withFancyUnderline,
    withUnderline,
    withHover,
    fontSize = '16px',
    bold,
    fontWeight = 600,
    alignSelf,
    isActive,
    mobileFontSize,
    lineHeight = '20px',
    bp = 'mobile',
    width,
    noStyle,
    display = 'inline-block',
  }) => css`
    display: ${display};
    width: ${width};

    ${!noStyle &&
    css`
      & > a,
      & > span {
        transition: 0.2s;
        text-decoration: none;
        color: ${white && 'white'};
        color: ${green && colors.green};
        color: ${red && colors.red};
        color: ${blue && colors.blue};
        text-transform: ${upperCase && 'uppercase'};
        display: inline-block;
        text-decoration: ${withUnderline && 'underline'};
        text-decoration: ${withFancyUnderline && 'none'};
        font-weight: ${fontWeight};
        align-self: ${alignSelf};
        line-height: ${lineHeight};
        cursor: pointer;

        &:hover {
          text-decoration: none;
          ${withHover &&
          css`
            color: ${colors.darkGreen};
          `}
        }

        ${addResponsivity()}

        font-size: ${mobileFontSize || fontSize};
        ${up(bp)} {
          font-size: ${fontSize};
        }

        /* fancy underline */
        ${withFancyUnderline &&
        css`
          &::after {
            content: '';
            display: block;
            width: 100%;
            width: calc(100% + 4px);
            height: 2px;
            position: relative;
            margin-top: 2.5px;
            opacity: 0;
            transition: 0.2s;

            background-image: linear-gradient(
              to right,
              ${colors.blue} 7px,
              rgba(255, 255, 255, 0) 0%
            );
            background-position: bottom left;
            background-size: 11px 100px;
            background-repeat: repeat-x;
          }

          ${isActive &&
          css`
            &::after {
              opacity: 1;
            }
            ${withHover &&
            css`
              color: ${colors.blue};
            `}
          `}
        `}
      }
    `}
  `}
`

const scrollTo = anchor => {
  const target = document.getElementById(anchor)
  if (target) {
    const pos = target.getBoundingClientRect().top + window.scrollY

    window.scroll({
      top: pos - 150, // subtract height of pinned menu on desktop
      behavior: 'smooth',
    })
  } else {
    console.log('NO TARGET!!!')
  }
}

// automatically fill route path with translated route
// automatically prepend path with /en/ or /de/ etc...
// when preferLang --> generate link in that lang === use to switch to other lang
//
const Link = injectIntl(
  ({
    to, // page route constant or dynamic route with /key/slug (eg. blog/article-slug)
    preferLang,
    asSpan,
    asA,
    href, // only for asA
    target,
    alt,
    onClick,
    children,
    anchor,
    // following is for user switching lang (TODO: in next version move to separate fn)
    pageKey,
    onlyLangs,
    intl: { language: currentLanguage, setLanguage },
    ...rest
  }) => {
    const link =
      typeof to !== 'undefined'
        ? translatePath(to, preferLang || currentLanguage, {
            pageKey,
            onlyLangs,
          })
        : undefined

    const handleClick = (e, { isA, href } = {}) => {
      if (preferLang) {
        setLanguage(preferLang)
      }
      if (onClick && !anchor) {
        onClick(e)
      }
      if (anchor && !isA) {
        // prevent redirecting to page
        e.preventDefault()

        const isAnotherPage = !link.startsWith('#')

        // start scrolling
        setTimeout(
          () => {
            scrollTo(anchor)
          },
          isAnotherPage ? 600 : 50,
        )

        // trigger user action
        if (onClick) {
          onClick(e)
        }

        // if it's another page
        if (isAnotherPage) {
          navigate(link)
        }
      }
      if (isA) {
        // Deal with anchor link on our page
        if (href && !href.startsWith('http') && href.indexOf('#') !== -1) {
          setTimeout(() => {
            const targetId = href.substring(href.indexOf('#') + 1)

            window.scroll({
              top: document.getElementById(targetId).offsetTop - 24, // subtract height of pinned menu on desktop
              behavior: 'smooth',
            })
          }, 100)

          e.preventDefault()
        }
      }
    }

    return (
      <LinkWrapper {...rest}>
        {asSpan && <span onClick={handleClick}>{children}</span>}
        {asA && (
          <a
            href={href}
            target={target}
            alt={alt}
            onClick={e => handleClick(e, { isA: true, href })}
          >
            {children}
          </a>
        )}
        {!asSpan && !asA && <GatsbyLink to={link} onClick={handleClick} children={children} />}
      </LinkWrapper>
    )
  },
)

Link.translatePath = translatePath

// define common link styles
// category, tym; zakrok - dalsich 10 fotos; vice o zakroku (blog)
Link.BasicLink = props => (
  <Link upperCase withFancyUnderline fontSize="14px" green withHover {...props} />
)

// procedure category link
Link.CategoryLink = props => <Link.BasicLink fontSize="16px" {...props} />

// Navbar link
Link.NavLink = props => <Link.BasicLink mobileFontSize="16px" bp="menu" {...props} />

// footer link TODO:
// Link.FooterLink = props => <Link.BasicLink white {...props} />

Link.Inline = props => <Link noStyle display="inline" {...props} />

Link.FooterLink = styled(Link)`
  ${({ theme: { colors } }) => css`
    a {
      color: ${colors.white};
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-decoration: underline;
      &:hover {
        color: ${colors.white};
      }
    }
  `}
`
// contact - blue links TODO:

Link.PageLinkComponent = injectIntl(
  ({ Component, settingsKey, isFile, children, intl: { language }, asA }) => {
    const { settings } = useStaticQuery(graphql`
      query PageLinkComponentQuery {
        settings {
          gdprPage {
            ...PageFragment
          }
          apartmentsPage {
            ...PageFragment
          }
          coronaPage {
            ...PageFragment
          }
          stornoConditions {
            cs {
              absoluteUrl
            }
            en {
              absoluteUrl
            }
            de {
              absoluteUrl
            }
          }
        }
      }
    `)

    const page = settings[settingsKey]

    const link = !isFile
      ? `/${(page && language && page.slug[language]) || ''}`
      : settings[settingsKey][language]?.absoluteUrl

    if (!page || link === '/') {
      return null
    }

    return (
      <Component to={!isFile ? link : null} href={isFile ? link : null} asA={isFile} target={isFile ? '_blank' : null}>
        {children}
      </Component>
    )
  },
)

// PAGE ROUTES CONSTS
Object.keys(links).forEach(link => {
  Link[link] = links[link]
})

export default Link
