import React from "react"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"
import Col from "./Col"

import topPath from "../data/images/hexa-box-top2.png"
import bottomPath from "../data/images/hexa-box-bottom2.png"

const Wrapper = styled(Col)`
  max-width: 312px;
`

const ContentWrapper = styled(Col)`
  ${({ theme: { colors } }) => css`
    border: 2px dashed ${colors.green};
    border-top-style: none;
    border-bottom-style: none;
    padding: 16px 40px;
  `}
`

const Inner = styled.div`
  margin: -25px 0;
`

const SideImage = styled.img`
  width: 100%;
`

const HexaBox = ({ children }) => (
  <Wrapper>
    <SideImage src={topPath} alt="" />
    <ContentWrapper>
      <Inner>{children}</Inner>
    </ContentWrapper>
    <SideImage src={bottomPath} alt="" />
  </Wrapper>
)

HexaBox.defaultProps = {}

HexaBox.propTypes = {
  children: PropTypes.node.isRequired,
}

export default HexaBox
