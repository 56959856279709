import React from "react"

export default function withScrollDetection(WrappedComponent) {
  return class extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
        direction: null,
        lastPosition: 0,
      }
    }

    componentDidMount() {
      window.addEventListener("scroll", this.handleScroll)
    }

    componentWillUnmount() {
      window.removeEventListener("scroll", this.handleScroll)
    }

    handleScroll = () => {
      const pos = -document.body.getBoundingClientRect().top
      const { lastPosition } = this.state
      if (lastPosition === pos) {
        return
      }

      this.setState({
        direction: pos > lastPosition ? "down" : "up",
        lastPosition: pos,
      })
    }

    render() {
      const { direction, lastPosition } = this.state

      return (
        <WrappedComponent
          scrollDirection={direction}
          scrollPosition={lastPosition}
          {...this.props}
        />
      )
    }
  }
}
