import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Slider from 'react-slick'
import { GatsbyImage } from 'gatsby-plugin-image'
import closePopupIconSrc from '../data/images/icon-close-menu.svg'
import Text from './Text'
import Gap from './Gap'
import { up } from '../lib/styles'

import arrowRightPath from '../data/images/arrow-right.svg'
import arrowLeftPath from '../data/images/arrow-left.svg'

const sliderSettings = {
  accessibility: true,
  dots: false,
  infinite: true,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  //   variableWidth: true,
  //   adaptiveHeight: true,
}

const PageOverlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: black;
  opacity: 0.7;
  z-index: 1000;
`

const Popup = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 1000;
  background: white;

  ${up('mobile')} {
    /* top: 5vh; */
    width: 100vw;
    height: 100vh;

    /* max-width: 1200px; */
    left: 50%;
    transform: translateX(-50%);
  }
`

const CloseIcon = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  padding: 32px;
  cursor: pointer;
`

const ImageWrapper = styled.div`
  position: relative;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
  ${up('mobile')} {
    padding: 0;
  }
`

const ContentWrapper = styled.div`
  position: relative;
  height: 100%;
  padding: 90px 0 54px;
  ${up('mobile')} {
    padding: 90px 80px 54px;
  }
`

const SlideWrapper = styled.div`
  position: relative;
`

const StyledSlider = styled(Slider)`
  position: relative;
  height: 100%;

  .slick-slider,
  .slick-list,
  .slick-track {
    height: 100%;
  }
  .slick-slide {
    height: 100%;
    div {
      height: 100%;
    }
  }
`

const CounterText = styled(Text)`
  ${({ theme: { colors } }) => css`
    color: ${colors.green};
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-transform: uppercase;
    text-align: left;
    position: absolute;
    top: 38px;
    left: 20px;

    ${up('mobile')} {
      text-align: center;
      left: 50%;
      transform: translateX(-50%);
    }
  `}
`

const ArrowWrapper = styled.div`
  position: absolute;
  padding: 50px 40px;
  cursor: pointer;
  img {
    width: 20px;
    height: auto;
  }
  bottom: 0;

  /* display: none; */
  ${up('mobile')} {
    top: 50%;
    padding: 100px 40px;
    transform: translateY(-50%);
    display: flex;
  }
`
const ArrowWrapperLeft = styled(ArrowWrapper)`
  left: 0;
  padding-left: 40px;
`
const ArrowWrapperRight = styled(ArrowWrapper)`
  right: 0;
  padding-right: 40px;
`

const Description = styled(Text)`
  height: auto !important;
  text-align: center;
  width: 100%;
  padding: 0 20px;
`

const getImage = (image = {}) => {
  if (image.image && image.image.gatsbyImageData) return image.image.gatsbyImageData

  if (image.filename && image.filename.image && image.filename.image.gatsbyImageData) {
    return image.filename.image.gatsbyImageData
  }
  return image
}

class GalleryPopup extends Component {
  state = {
    current: 0,
    isOpen: false,
    initialSlide: 0,
    allowPreload: false,
  }

  next = () => this.slick && this.slick.slickNext()

  prev = () => this.slick && this.slick.slickPrev()

  setCurrent = (current, next) => this.setState({ current: next })

  close = () => {
    this.setState({ isOpen: false, current: 0, initialSlide: 0 })
  }

  open = (index = 0) => {
    const current = !isNaN(Number(index)) ? index : 0
    this.setState({ isOpen: true, initialSlide: current, current })

    setTimeout(() => {
      this.setState({
        allowPreload: true,
      })
    }, 1000)
  }

  render() {
    const { data } = this.props
    const { current, isOpen, initialSlide, allowPreload } = this.state
    // console.log('data', data)
    if (!isOpen) return null

    return (
      <>
        <PageOverlay onClick={this.close} />
        <Popup>
          <ContentWrapper>
            <CounterText>
              {current + 1}/{data.length}
              <Gap gap="32px" />
            </CounterText>
            <StyledSlider
              {...sliderSettings}
              beforeChange={this.setCurrent}
              initialSlide={initialSlide}
              ref={ref => {
                this.slick = ref
              }}
            >
              {data.map(({ image, text }, index) => (
                <SlideWrapper key={index}>
                  <ImageWrapper>
                    <GatsbyImage
                      image={getImage(image)}
                      style={{ height: 'auto' }}
                      imgStyle={{
                        objectFit: 'contain',
                      }}
                      critical={current === index}
                      fadeIn={
                        allowPreload
                          ? index > current + 1 || index < current - 1
                          : current !== index
                      }
                      loading={current === index ? 'eager' : 'lazy'}
                    />
                    <Gap gap="32px" />
                    <Description>{text}</Description>
                  </ImageWrapper>
                </SlideWrapper>
              ))}
            </StyledSlider>
          </ContentWrapper>

          <ArrowWrapperLeft onClick={this.prev}>
            <img src={arrowLeftPath} alt="" />
          </ArrowWrapperLeft>
          <ArrowWrapperRight onClick={this.next}>
            <img src={arrowRightPath} alt="" />
          </ArrowWrapperRight>
          <CloseIcon src={closePopupIconSrc} onClick={this.close} />
        </Popup>
      </>
    )
  }
}

GalleryPopup.defaultProps = {}

GalleryPopup.propTypes = {
  data: PropTypes.array.isRequired,
}

export default GalleryPopup
