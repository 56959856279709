import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import injectIntl from '../lib/intl/injectIntl'
import { languages, translatePath } from '../lib/intl'

const SEO = injectIntl(
  ({
    title,
    titleTemplate,
    description,
    image,
    pathname,
    isArticle,
    intl: { language, pathKey, pageKey, onlyLangs, slugs, ...rest },
    ...restAll
  }) => (
    <StaticQuery
      query={query}
      render={({
        site: {
          siteMetadata: { defaultTitleTemplate, siteUrl, defaultImage },
        },
      }) => {
        const defaultDescription = {
          cs: 'Jsme renomovaná klinika plastické chirurgie. Můžeme se chlubit tím nejlepším personálem, za kterým stojí velký tým plastických chirurgů a anesteziologů se zkušenostmi z celého světa s více než tisícovkou úspěšných operací na kontě.',
          en: '',
          de: '',
        }

        const seo = {
          title,
          description: description || defaultDescription[language],
          image: `${siteUrl}${image || defaultImage}`,
          url: `${siteUrl}${pathname || '/'}`,
          titleTemplate: titleTemplate || defaultTitleTemplate[language],
        }

        const alternativePaths = languages.map(lang => {
          const slug = (slugs && slugs[lang]) || false
          const pathKeyWithSlug = slug ? `${pathKey}/${slug}` : pathKey

          const translatedPath = translatePath(pathKeyWithSlug, lang, {
            pageKey,
            onlyLangs,
          })
          // console.log('translatedPath', translatedPath, pathname, lang)

          return { lang, path: `https://www.formeclinic.cz${translatedPath}` }
        })

        // console.log(
        //   'alternativePaths',
        //   pathname,
        //   alternativePaths,
        //   pathKey,
        //   pageKey,
        //   rest,
        //   restAll,
        //   slugs,
        // )

        // const finalTitle = seo.titleTemplate.replace(/%s/g, seo.title)
        return (
          <>
            <Helmet title={seo.title} titleTemplate={seo.titleTemplate}>
              <meta name="description" content={seo.description} />
              <meta name="image" content={seo.image} />
              <meta name="facebook-domain-verification" content="u84s7fl9d0z709gjcj6pq0v8wwhbwe" />
              <link rel="stylesheet" href="/notifee.css" type="text/css" />

              <html lang={language} />
              {pageKey !== 'PostTemplate' &&
                alternativePaths.map(o => <link rel="alternate" href={o.path} hrefLang={o.lang} />)}

              {/* FB open graph */}
              {seo.url && <meta property="og:url" content={seo.url} />}
              {isArticle ? (
                <meta property="og:type" content="article" />
              ) : (
                <meta property="og:type" content="website" />
              )}
              {seo.title && <meta property="og:title" content={seo.title} />}
              {seo.description && <meta property="og:description" content={seo.description} />}
              {seo.image && <meta property="og:image" content={seo.image} />}

              {/* Twitter */}
              <meta name="twitter:card" content="summary_large_image" />
              {/* {twitterUsername && (
                <meta name="twitter:creator" content={twitterUsername} />
              )} */}
              {seo.title && <meta name="twitter:title" content={seo.title} />}
              {seo.description && <meta name="twitter:description" content={seo.description} />}
              {seo.image && <meta name="twitter:image" content={seo.image} />}

              {/* make it nice */}
              <meta name="theme-color" content="#ecf9ff" />
            </Helmet>
          </>
        )
      }}
    />
  ),
)

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  isArticle: PropTypes.bool,
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
  isArticle: false,
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitleTemplate: titleTemplate {
          cs
          en
          de
        }
        # defaultDescription: description {
        #   cs
        #   en
        #   de
        # }
        # defaultDescription: description
        siteUrl: url
        defaultImage: image
      }
    }
  }
`
