import React from "react"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import Title from "./Title"
import Text from "./Text"
import Col from "./Col"
import Row from "./Row"
import Gap from "./Gap"
import WaveImage from "./WaveImage"
import Button from "./Button"
import VH from "./VH"
import Link from "./Link"
import T from "../lib/intl/T"

import { up } from "../lib/styles"

const ImageWrapper = styled.div`
  position: relative;
`

const PositionText = styled(Text.Italic)`
  font-size: 16px;
  line-height: 24px;
  ${up("menu")} {
    font-size: 14px;
    line-height: 24px;
  }
`

const ImagesCol = styled(Col)`
  padding-right: 0;
  ${up("menu")} {
    padding-right: 50px;

    padding-left: 195px;
  }

  ${up("menu")} {
    padding-left: 0;
  }
`

const DoctorInfo = styled.div`
  ${({ theme, first, second, third }) => css`
    position: relative;
    text-align: center;

    ${up("menu")} {
      position: absolute;
      text-align: left;
      ${first &&
      css`
        /* top: 100px; */
        /* top: 16%; */
        top: 23%;
        /* right: -45px; */
        /* right: -8%; */
        right: -11%;
      `}

      ${second &&
      css`
        bottom: 136px;
        left: -250px;
      `}
    
    ${third &&
      css`
        bottom: -10px;
        right: 5px;
      `}
    }
  `}
`

const DoctorImage = styled(GatsbyImage)`
  display: block;
  margin: auto;
`

const DoctorImageWrapper = styled.div`
  display: block;

  ${up("menu")} {
    display: none;
  }
`

const OurDoctorsSection = ({ image, doctorsImages }) => (
  <Row responsive bp="menu">
    <Col maxWidth="391px" alignItems="flex-start">
      <WaveImage />
      <Gap gap="24px" />
      <Title.BigTitle>
        <T>Naši lékaři</T>
      </Title.BigTitle>
      <Gap gap="24px" mobileGap="16px" />
      <Text responsive>
        <T>
          Náš tým se skládá ze skvělých profesionálů ve svém oboru. Postará se o
          Vaše pohodlí, zdraví i bezpečí. Pokud se chcete svěřit do rukou lidem,
          kteří mají rádi svou práci a záleží jim na tom, co dělají, jste na
          správném místě.
        </T>
      </Text>
    </Col>
    <ImagesCol alignItems="center">
      <Col alignItems="center">
        <ImageWrapper>
          <DoctorInfo first>
            <Link to="/mudr-petr-jan-vasek">
              <DoctorImageWrapper>
                <Gap gap="24px" />
                <DoctorImage image={doctorsImages[0].image.gatsbyImageData} />
                <Gap gap="16px" />
              </DoctorImageWrapper>
              <Title.SmallerTitle>MUDr. Petr Jan Vašek</Title.SmallerTitle>
              <PositionText>
                <T>Primář Formé Clinic</T>
              </PositionText>
            </Link>
          </DoctorInfo>
          <DoctorInfo second>
            <Link to="/mudr-radek-lhotsky">
              <DoctorImageWrapper>
                <Gap gap="42px" />
                <DoctorImage image={doctorsImages[1].image.gatsbyImageData} />
                <Gap gap="16px" />
              </DoctorImageWrapper>
              <Title.SmallerTitle>MUDr. Radek Lhotský</Title.SmallerTitle>
              <PositionText>
                <T>Plastický chirurg</T>
              </PositionText>
            </Link>
          </DoctorInfo>
          <DoctorInfo third>
            <Link to="/mudr-matus-baran-mba">
              <DoctorImageWrapper>
                <Gap gap="42px" />
                <DoctorImage image={doctorsImages[2].image.gatsbyImageData} />
                <Gap gap="16px" />
              </DoctorImageWrapper>
              <Title.SmallerTitle>MUDr. Matúš Baran, MBA</Title.SmallerTitle>
              <PositionText>
                <T>Plastický chirurg Formé Clinic</T>
              </PositionText>
            </Link>
          </DoctorInfo>

          <Gap gap="42px" />
          <VH showAfter="menu">
            <GatsbyImage
              image={image.image.gatsbyImageData}
              style={{ pointerEvents: "none" }}
            />
          </VH>
        </ImageWrapper>
        <Gap gap="30px" mobileGap="0" bp="menu" />
        <Button.Link to={Link.TEAM} dashed responsiveFullWidth bp="menu">
          <T>Celý tým</T>
        </Button.Link>
      </Col>
    </ImagesCol>
  </Row>
)

OurDoctorsSection.defaultProps = {}

OurDoctorsSection.propTypes = {
  image: PropTypes.object.isRequired,
  doctorsImages: PropTypes.array.isRequired,
}

export default OurDoctorsSection
