import React from "react"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"
import { up, addResponsivity, addFullWidth } from "../lib/styles"

import upPath from "../data/images/hexa-top.png"
import upPathMobile from "../data/images/hexa-top-mobile.png"
import downPath from "../data/images/hexa-bottom.png"
import downPathMobile from "../data/images/hexa-bottom-mobile.png"

const Wrapper2 = styled.div`
  position: relative;
  width: 100%;
`

const Wrapper = styled.div`
  position: relative;
  font-size: 0;
  overflow: hidden;

  ${addFullWidth()};
`

const ContentWrapper = styled.div`
  ${({
    theme: { colors },
    paddingBottom,
    paddingTop,
    mobilePaddingTop,
    mobilePaddingBottom,
  }) => css`
    position: relative;
    background-color: ${colors.lightBlue};
    padding-top: ${mobilePaddingTop};
    padding-bottom: ${mobilePaddingBottom};
    ${up("mobile")} {
      padding-top: ${paddingTop};
      padding-bottom: ${paddingBottom};
    }
  `}
`

const SideImage = styled.img`
  position: relative;
  width: 100%;
  ${addResponsivity()}
  pointer-events: none;
`
const backgroundBreakpoint = "mobile"

const SideImageWrapper = styled.div`
  position: absolute;
  width: calc(100% + 26px);
  left: -13px;
  z-index: 10;
  pointer-events: none;

  ${up(backgroundBreakpoint)} {
    width: calc(100% + 10px);
    left: -5px;
  }
  ${up(800)} {
    width: calc(100% + 14px);
    left: -7px;
  }
  ${up(1000)} {
    width: calc(100% + 20px);
    left: -10px;
  }
`

const SideImageWrapperTop = styled(SideImageWrapper)`
  top: -2px;
`

const SideImageWrapperBottom = styled(SideImageWrapper)`
  bottom: -2px;
`

const HexaSection = ({ children, isFooter, ...props }) => (
  <Wrapper2>
    <Wrapper>
      <ContentWrapper {...props}>
        <SideImageWrapperTop>
          <SideImage showAfter={backgroundBreakpoint} src={upPath} alt="" />
          <SideImage
            hideAfter={backgroundBreakpoint}
            src={upPathMobile}
            alt=""
          />
        </SideImageWrapperTop>
        {children}
        {!isFooter && (
          <SideImageWrapperBottom>
            <SideImage showAfter={backgroundBreakpoint} src={downPath} alt="" />
            <SideImage
              hideAfter={backgroundBreakpoint}
              src={downPathMobile}
              alt=""
            />
          </SideImageWrapperBottom>
        )}
      </ContentWrapper>
    </Wrapper>
  </Wrapper2>
)

HexaSection.defaultProps = {
  paddingTop: "120px",
  paddingBottom: "120px",
  mobilePaddingTop: "100px",
  mobilePaddingBottom: "100px",
  isFooter: false,
}

HexaSection.propTypes = {
  children: PropTypes.node.isRequired,
  paddingTop: PropTypes.string,
  paddingBottom: PropTypes.string,
  mobilePaddingTop: PropTypes.string,
  mobilePaddingBottom: PropTypes.string,
  isFooter: PropTypes.bool,
}

export default HexaSection
