import React from "react"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"

const PlayVideoIcon = props => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity="0.897602"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42 28L20 42L20 14L42 28Z"
      fill="#00B3A6"
    />
  </svg>
)

PlayVideoIcon.defaultProps = {}

PlayVideoIcon.propTypes = {}

export default PlayVideoIcon
