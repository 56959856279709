import React, { Component } from "react"
import PropTypes from "prop-types"
import YouTube from "react-youtube"
import styled, { css } from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import VH from "./VH"
import PlayVideoIcon from "./icons/playVideo"
import videoMask from "../data/images/career-video-mask.png"
import { up } from "../lib/styles"

const VideoWrapper = styled.div`
  position: relative;
  display: inline-block;
  overflow: hidden;
  font-size: 0;
  width: 100%;
  max-width: 408px;
  cursor: pointer;
`

const VideoMask = styled.img`
  width: 100%;
`

const CenteredPlayVideoIcon = styled(PlayVideoIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const Player = styled(YouTube)`
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  height: 100%;

  width: 166%;
  left: -33%;
`

const Thumbnail = styled(GatsbyImage)`
  max-width: 100%;
  width: 408px;
`

class Video extends Component {
  video = null

  constructor(props) {
    super(props)
    this.state = {
      isPlaying: false,
    }
  }

  saveRef = e => {
    this.video = e.target
  }

  startVideo = () => {
    if (!this.video) return
    // TODO - zkoušet spustit ve smyčce, pokud toto bude dělat
    this.video.playVideo()
    this.setState({ isPlaying: true })
  }

  pauseVideo = () => {
    if (!this.video) return

    this.setState({ isPlaying: false })
    this.video.pauseVideo()
  }

  stateChange = ({ data }) => {
    // video ended
    if (data === 0) this.setState({ isPlaying: false })
    if (data === 2) this.setState({ isPlaying: false })
  }

  render() {
    const { thumbnail, videoId, ...rest } = this.props
    const { isPlaying } = this.state
    return (
      <VideoWrapper>
        <VH show={!isPlaying} onClick={this.startVideo}>
          <Thumbnail image={thumbnail.image.gatsbyImageData} />
          <CenteredPlayVideoIcon />
        </VH>
        <VH
          show={isPlaying}
          onClick={this.pauseVideo}
          displayAs="inline-block"
          style={{ position: "relative" }}
        >
          <VideoMask src={videoMask} alt="" />
          <Player
            videoId={videoId}
            opts={{
              width: 416,
              height: 406,
              playerVars: {
                modestbranding: 1,
                controls: 0,
                rel: 0,
              },
            }}
            {...rest}
            onReady={this.saveRef}
            onStateChange={this.stateChange}
          />
        </VH>
      </VideoWrapper>
    )
  }
}

Video.defaultProps = {}

Video.propTypes = {
  thumbnail: PropTypes.object.isRequired,
  videoId: PropTypes.string.isRequired,
}

export default Video
