import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled, { css } from "styled-components"
import { addResponsivity, up, down, between } from "../lib/styles"

/**
 * Not just rounded image :O
 * 12 and 16 px radius
 */
const RoundedImage = styled(GatsbyImage)`
  ${addResponsivity()}

  ${({ big, noMask, noCrop, small }) => css`
    border-radius: ${big ? 16 : small ? 8 : 12}px;
    /* border-radius: ${big ? 16 : 12}px; */

    /* // this is safari fix */
    -webkit-mask-image: -webkit-radial-gradient(white, black);
  `}
`

export default RoundedImage
