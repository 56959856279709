import React, { Fragment, useContext } from "react"
import axios from "axios"
import { navigate } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"
import TextField from "./TextField"
import TextAreaField from "./TextAreaField"
import Gap from "./Gap"
import Col from "./Col"
import Row from "./Row"
import Button from "./Button"
import Text from "./Text"
import SelectFileField from "./SelectFileField"
import { up } from "../lib/styles"
import T from "../lib/intl/T"
import injectIntl from "../lib/intl/injectIntl"
import { urlBase, maxNumberOfFormImages } from "../lib/config"
import { ModalContext } from "./Modals"
import { ConfirmPopup } from "./AllPopups"
import Link from "./Link"
import { trackLead } from "../lib/fb"

const maxNumberOfImages = 10

const validateEmail = email => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

const FormGap = () => <Gap gap="14px" />

const fieldsDef = ["name", "email", "phoneNumber", "message"]

const FormCol = styled(Col)`
  max-width: none;
  ${up("mobile")} {
    max-width: 455px;
  }
`

const AddPictureText = styled(Text)`
  color: #00b3a6;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  display: inline-block;
  align-self: flex-start;
`

const SpamCheck = styled.input`
  display: none;
`

const createFields = ({ fieldsDef, state, setState }) =>
  fieldsDef.reduce((result, key) => {
    result[key] = {
      value: state[key],
      error: state[`${key}Error`],
      onChange: e => {
        setState({ [key]: e.target.value })
      },
      onBlur: () => setState({ [`${key}Error`]: "" }),
    }
    return result
  }, {})

class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.spamCheck = React.createRef()
    this.state = {
      name: "",
      email: "",
      message: "",
      files: [],

      isSending: false,
    }
  }

  setSelectedFiles = e => {
    const { files } = this.state
    let newFiles = [...files, ...e.target.files]
    if (newFiles.length > maxNumberOfImages)
      newFiles = newFiles.slice(0, maxNumberOfImages)
    this.setState({ files: newFiles })
  }

  removeFile = key => {
    const { files } = this.state
    const newFiles = files.filter((file, i) => i !== key)
    this.setState({ files: newFiles })
  }

  onSendClick = async () => {
    const {
      intl: { language },
      hasPicture,
    } = this.props
    const { name, email, phoneNumber, message, files } = this.state

    this.setState({ isSending: true })
    const {
      onIsSent,
      intl: { t },
    } = this.props

    const errors = {}
    if (!email) errors.emailError = t("Email je povinný, prosím doplňte")
    if (email && !validateEmail(email))
      errors.emailError = t("Email není validní, prosím doplňte")
    if (!name) errors.nameError = t("Jméno je povinné, prosím doplňte")
    if (!message) errors.messageError = t("Dotaz je povinný, prosím doplňte")
    if (!phoneNumber)
      errors.phoneNumberError = t("Telefon je povinný, prosím doplňte")

    this.setState(errors)
    if (Object.keys(errors).length !== 0)
      return this.setState({ isSending: false })

    const data = new FormData()
    files.forEach(file => data.append("images", file))
    data.append("name", name)
    data.append("email", email)
    data.append("message", message)
    data.append("lang", language)
    data.append("isSpam", !!this.spamCheck.current.value)
    data.append("phoneNumber", phoneNumber)
    data.append("locationUrl", window.location.href)
    data.append("isHP", hasPicture)
    try {
      await axios.post("/api/v1/inquiries/standard", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      trackLead()
      onIsSent() // shows thanks message in upper component
      navigate(`${window.location.pathname}#contact-form`) // adjusts view to thansk message
      this.context.showModal(ConfirmPopup)
    } catch (error) {
      alert(
        t("Došlo k chybě při odesílání formuláře, zkuste to prosím později.")
      )
    }
    this.setState({ isSending: false })
  }

  render() {
    const {
      hasPicture,
      intl: { t },
      ctaName,
    } = this.props
    const { files, isSending } = this.state
    const fields = createFields({
      fieldsDef,
      state: this.state,
      setState: value => this.setState(value),
    })

    return (
      <FormCol>
        <TextField label={t("Jméno a příjmení")} {...fields.name} />
        <FormGap />
        <TextField label={t("Email")} {...fields.email} type="email" />
        <SpamCheck
          ref={this.spamCheck}
          id="email-check"
          name="emailCheck"
          type="email"
        />
        <FormGap />
        <TextField label={t("Telefon")} {...fields.phoneNumber} />
        <Gap gap="4px" />
        <Text fontSize="14px" lineHeight="18px">
          <T>Pro rychlejší komunikaci nám prosím napište telefonní číslo.</T>
        </Text>
        <FormGap />
        <TextAreaField label={t("Dotaz")} {...fields.message} />
        {hasPicture && (
          <>
            <Gap gap="16px" />

            {files.map(({ name }, key) => (
              <Row key={name}>
                <Text>{name}</Text>
                <Gap gap="8px" />
                <AddPictureText
                  onClick={() => this.removeFile(key)}
                  style={{ cursor: "pointer" }}
                >
                  <T>smazat</T>
                </AddPictureText>
              </Row>
            ))}
            {files.length !== 0 && <Gap gap="8px" />}
            {files.length < maxNumberOfImages && (
              <SelectFileField onSelect={this.setSelectedFiles}>
                <AddPictureText>
                  + <T>Přidat fotku</T>
                </AddPictureText>
              </SelectFileField>
            )}
            <Gap gap="10px" />
          </>
        )}
        <Gap gap="24px" />
        <Row>
          <Button
            responsiveFullWidth
            onClick={this.onSendClick}
            disabled={isSending}
            data-cta={ctaName}
          >
            {!isSending ? <T>Odeslat</T> : <T>Odesílám...</T>}
          </Button>
        </Row>

        <Gap gap="12px" />
        <Text fontSize="11px" lineHeight="14px">
          <T>Odesláním formuláře souhlasíte se</T>{" "}
          <Link.PageLinkComponent
            settingsKey="gdprPage"
            Component={Link.Inline}
          >
            <Text
              fontSize="11px"
              lineHeight="14px"
              style={{ textDecoration: "underline", display: "inline" }}
            >
              <T>zásadami zpracování a ochrany osobních údajů.</T>
            </Text>
          </Link.PageLinkComponent>
        </Text>
        <Gap gap="30px" />
      </FormCol>
    )
  }
}

ContactForm.contextType = ModalContext

ContactForm.defaultProps = {
  onIsSent: () => null,
  hasPicture: false,
  ctaName: "form",
}

ContactForm.propTypes = {
  onIsSent: PropTypes.func,
  hasPicture: PropTypes.bool,
  intl: PropTypes.object.isRequired,
  ctaName: PropTypes.string,
}

export default injectIntl(ContactForm)
