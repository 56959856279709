import React from "react"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import Row from "./Row"
import Col from "./Col"
import Text from "./Text"
import Title from "./Title"
import Link from "./Link"
import Gap from "./Gap"
import WaveImage from "./WaveImage"
import Button from "./Button"
import VH from "./VH"
import T from "../lib/intl/T"

import { up } from "../lib/styles"

// const mobileBp = 800 // menu 930
const mobileBp = 840 // menu 930
// const firstBp = 880
const firstBp = 1040

const ImageWrapper = styled.div`
  position: relative;
  /* ${up(mobileBp)} {
    margin-left: 97px;
  } */
  ${up(firstBp)} {
    margin-left: 97px;
  }
`

const ButtonGap = styled.div`
  display: block;
  height: 24px;
  ${up(mobileBp)} {
    width: 323px;
  }
  ${up(firstBp)} {
    width: 420px;
  }
`

const Info = styled.div`
  ${({ theme, first, second, third }) => css`
    position: relative;
    text-align: left;
    width: 100%;
    max-width: 317px;

    ${up(mobileBp)} {
      position: absolute;
      text-align: left;
      ${first &&
      css`
        top: 53px;
        left: 308px;
      `}

      ${second &&
      css`
        top: 280px;
        left: 476px;
      `}
    
    ${third &&
      css`
        top: 540px;
        left: 336px;
      `}
    }
  `}
`

const WhyImage = styled(GatsbyImage)`
  display: block;
  /* margin: auto; */
`

const WhyImageWrapper = styled.div`
  display: block;

  ${up(mobileBp)} {
    display: none;
  }
`

const WhyUsSection = ({ image, mobileImages }) => {
// console.log('image, mobileImages',image, mobileImages )

return (
  <Row>
    <Gap gap="97px" mobileGap="0" bp="menu" />
    <Col>
      <Col maxWidth="489px" alignItems="flex-start">
        <WaveImage />
        <Gap gap="24px" />
        <Title.BiggerTitle>
          <T>Proč si vybrat nás?</T>
        </Title.BiggerTitle>
        <Gap gap="24px" />
        <Text responsive>
          <T>
            Jsme výjimeční osobním přístupem. Nechodí k nám nechodí jen
            celebrity, jsme klinika pro běžné lidi, s rodinnou atmosférou.
            Nejsme továrna na nová těla, ale specializovaná klinika s pěti
            lůžky, kde je dost času na nadstandard.
          </T>
        </Text>
      </Col>
      <Gap gap="40px" />
      <Col>
        <ImageWrapper>
          <Info first>
            <WhyImageWrapper>
              <WhyImage
                image={mobileImages[0].image.gatsbyImageData}
                alt="Clinic interior, team of doctors, mudr. Jan Vasek."
              />
              <Gap gap="16px" />
            </WhyImageWrapper>
            <Title.SmallerTitle>
              <T>Konzultace je zdarma a nezávazná</T>
            </Title.SmallerTitle>
            <Text responsive>
              <T>
                Přijďte navštívit naši kliniku a zeptejte se našich odborníků na
                cokoliv. Vždy si na vás rádi udělají čas.
              </T>
            </Text>
          </Info>

          <Info second>
            <Gap gap="40px" />
            <WhyImageWrapper>
              <WhyImage
                image={mobileImages[1].image.gatsbyImageData}
                alt="Doctor consulting with patient"
              />
              <Gap gap="16px" />
            </WhyImageWrapper>
            <Title.SmallerTitle>
              <T>Apartmány v budově kliniky</T>
            </Title.SmallerTitle>
            <Text responsive>
              <T>
                Na naší klinice máme k dispozici moderní apartmány pro klienty z
                daleka i pro rodinné příslušníky.
              </T>
            </Text>
          </Info>

          <Info third>
            <Gap gap="10px" />
            <WhyImageWrapper>
              <WhyImage
                image={mobileImages[2].image.gatsbyImageData}
                alt="Clinic interiors"
              />
              <Gap gap="16px" />
            </WhyImageWrapper>
            <Title.SmallerTitle>
              <T>Přes 70 tisíc spokojených klientů</T>
            </Title.SmallerTitle>
            <Text responsive>
              <T>
                kteří se obrátili na naši kliniku a užívají si výsledky naší
                práce. A že je něco jinak, neuvidíte jen Vy, ale také Vaše
                okolí.
              </T>
            </Text>
          </Info>
          <VH showAfter={mobileBp}>
            <GatsbyImage
              image={image.image.gatsbyImageData}
              alt="Happy clients"
            />
          </VH>
        </ImageWrapper>
        <Row responsive bp={mobileBp}>
          <ButtonGap />
          <Col maxWidth="317px">
            <Button.Link
              to={Link.ABOUT}
              responsiveFullWidth
              bp={mobileBp}
              dashed
            >
              <T>Více o naší klinice</T>
            </Button.Link>
          </Col>
        </Row>
      </Col>
    </Col>
  </Row>
)
}

WhyUsSection.defaultProps = {}

WhyUsSection.propTypes = {
  image: PropTypes.object.isRequired,
  mobileImages: PropTypes.array.isRequired,
}

export default WhyUsSection
