import styled, { css } from "styled-components"

const Input = styled.input`
  ${({ theme: { colors, fonts }, hasError }) => css`
    height: 40px;
    border-radius: 8px;
    border: 1px solid #d8d8d8;
    ${hasError &&
    css`
      border: 2px solid ${colors.red};
    `}
    background-color: ${colors.white};
    outline: none;
    padding: 8px 12px;

    color: ${colors.black};
    font-family: ${fonts.muli};
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;

    &:focus {
      border: 2px solid ${colors.green};
      padding: 8px 10px;
    }
  `}
`

export default Input
