import React from "react"
import { createGlobalStyle, ThemeProvider, css } from "styled-components"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import styledNormalize from "styled-normalize"
import "../lib/fragments" // has to be imported somewhere to gatsby notice that
import { theme } from "../lib/styles"
import fontFiles from "../lib/fonts"
import IntlProvider from "../lib/intl/IntlProvider"
import { ModalProvider } from "../components/Modals"

const GlobalStyle = createGlobalStyle`${({ theme: { colors } }) => css`
  ${styledNormalize}
  ${fonts}

  html {
    /* scroll-behavior: smooth; */
  }

  body {
    background: white;
    font-family: "Muli", sans-serif;
    color: ${colors.text};
  }
  * {
    box-sizing: border-box;
  }
  p {
    margin: 0;
    padding: 0;
  }
  a {
    text-decoration: none;
    color: inherit;
    /* display: inline-block;
    color: ${colors.green};
    &:hover {
      color: ${colors.darkGreen};
    } */
  }
`}`

class App extends React.Component {
  render() {
    const { intl } = this.props
    return (
      <ThemeProvider theme={theme}>
        <ModalProvider>
          <>
            <GlobalStyle />
            <IntlProvider intl={intl}>{this.props.children}</IntlProvider>
          </>
        </ModalProvider>
      </ThemeProvider>
    )
  }
}

export default App

const fonts = css`
  @font-face {
    font-family: "Signika";
    font-style: normal;
    font-display: swap;
    font-weight: 600;
    src: local("Signika"), url(${fontFiles.SignikaSemiBold}) format("truetype");
  }
  @font-face {
    font-family: "Signika";
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: local("Signika"), url(${fontFiles.SignikaRegular}) format("truetype");
  }
  @font-face {
    font-family: "Signika";
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: local("Signika"), url(${fontFiles.SignikaLight}) format("truetype");
  }
  @font-face {
    font-family: "Muli";
    font-display: swap;
    font-style: normal;
    font-weight: 600;
    src: local("Muli"), url(${fontFiles.MuliSemiBold}) format("truetype");
  }
  @font-face {
    font-family: "Muli";
    font-display: swap;
    font-style: normal;
    font-weight: 700;
    src: local("Muli"), url(${fontFiles.MuliExtraBold}) format("truetype");
  }
  @font-face {
    font-family: "Muli";
    font-style: italic;
    font-display: swap;
    font-weight: 400;
    src: local("Muli"), url(${fontFiles.MuliRegularItalic}) format("truetype");
  }
  @font-face {
    font-family: "Muli";
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: local("Muli"), url(${fontFiles.MuliRegular}) format("truetype");
  }
`
