/* eslint-disable no-multi-assign */
module.exports = exports = {
  Settings: ['stornoConditions'],
  Procedure: ["name", "perex", "slug", "subtitle", "content", "metaDesc"],
  ProcedureCategory: ["name"],
  Person: ["name", "perex", "position", "content", "hasDetailPage", "metaDesc"],
  Page: ["title", "slug", "content", "isPublished", "metaDesc"],
  PersonCategory: ["name"],
  PersonImage: [],
  PersonHighScore: ["what"],
  Price: ["name", "amount"],
  Testimonial: ["content", "name", "info"],
  QATopic: ["name"],
}
