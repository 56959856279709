/* eslint-disable prefer-object-spread */
const axios = require('axios')
const modelsFields = require('./fieldsDefs')
const pagesConfig = require('../../../pagesConfig')

// console.log('axios', axios)

// package config below
const SAVE_NOT_FOUND = process.env.NODE_ENV !== 'production'

const languages = ['cs', 'en', 'de']
const defaultLanguage = 'cs'

const messages = {
  cs: require('../../../translations/cs.js'),
  en: require('../../../translations/en.js'),
  de: require('../../../translations/de.js'),
}

const countryCodesMapping = {
  CZ: 'cs',
  SK: 'cs',
  DE: 'de',
  AT: 'de',
  OTHER: 'en',
}

// special page settings
// via translate key
// e.g. for blog, where is no counterpart in another lang
// const pagesConfig = {
//   '/blog/*': {
//     fallbackKey: '/blog', // if translation was not found, use fallback key path
//     // in future - forceKey
//     onlyPrefix: true, // translate only prefix - e.g. route has slug in it
//     translateRoot: true,
//   },
//   '/procedures/*': {
//     onlyPrefix: true,
//     translateRoot: true,
//   },
//   '/team/*': {
//     onlyPrefix: true,
//     translateRoot: true,
//   },
// }

// helpers and fns
const getLangByCountry = countryCode =>
  countryCodesMapping[countryCode] || countryCodesMapping.OTHER

const t = (key, lang, disableDebugAppend) => {
  // const keyNumber = keyMap[key]
  let translated = messages[lang][key]
  if (!translated) {
    translated = key

    if (SAVE_NOT_FOUND && !disableDebugAppend) {
      console.log('appending!', key)
      axios
        .post('http://localhost:3005/api/v1/translations/append', {
          key,
          lang,
        })
        .then(response => {
          console.log(response)
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
  return translated
}

const findKey = (translated, lang) => {
  const key = Object.keys(messages[lang]).find(key => messages[lang][key] === translated)
  return key
}

const findPageConfig = ({ linkKey, pageKey }) => {
  let conf = pagesConfig[pageKey]

  // if (!conf && pageKey) {
  if (!conf || pageKey) {
    Object.keys(pagesConfig).forEach(key => {
      const thisConf = pagesConfig[key]
      if (thisConf.pathKey.endsWith('*')) {
        const rootKey = thisConf.pathKey.slice(0, -1)
        if (linkKey.startsWith(rootKey)) {
          conf = thisConf
        }
      }
    })
  }

  return conf || {}
}

// arg - onlyLangs - used for fallbackPath for generated pages
const translatePath = (linkKey, lang, { onlyLangs = languages, pageKey } = {}) => {
  if (!linkKey) return null

  // console.log('translatePath', linkKey, onlyLangs, pageKey)
  const conf = findPageConfig({ linkKey, pageKey })
  // console.log(conf.translateRoot, conf.rootKey)

  // fallback to key itself
  let translatedLink = linkKey

  // 1) check if desired lang is outside page lang capabilities
  if (!onlyLangs.includes(lang)) {
    if (conf.fallbackKey) {
      // if we have fallback key, use it
      translatedLink = t(conf.fallbackKey, lang, true)
    } else {
      // if not, we will be redirected to hp
      translatedLink = '/'
    }

    // 2) check if there is root and slug, eg. /procedures/slug, we want to translate the root?
  } else if (conf.translateRoot && conf.rootKey) {
    translatedLink = `${t(conf.rootKey, lang, true)}${linkKey.substr(conf.rootKey.length)}`
  } else {
    // 3) if not, translate it the normal way
    translatedLink = t(linkKey, lang, true)
  }

  // if (!conf.onlyPrefix) {
  //   // translate key
  //   translatedLink = t(linkKey, lang, true, true)
  //   // if page was not translated and has fallbackKey -- try it again
  //   // TODO: toto je asi nejaky pozustatek. vyresit. to cele co zacina s onlyPrefix je nejaky divny..
  //   if (!translatedLink || conf.fallbackKey) {
  //     translatedLink = t(linkKey, lang, true)
  //   }
  // }

  // finally we also want to prefix path with lang e.g. 'en'
  translatedLink = prefixPath(translatedLink, lang)
  return translatedLink
}

const prefixPath = (link, lang) => {
  if (!link) return null
  if (defaultLanguage !== lang) {
    link = `/${lang}${link}`
  }
  return link
}

// helpers for data from graphql
// if modelNameArg is not defined, it tries to get it from db
const applyLang = (arr, lang, modelNameArg) =>
  arr.map(o => {
    const res = Object.assign({}, o)
    const modelName = modelNameArg || res.modelName

    if (modelsFields[modelName]) {
      modelsFields[modelName].forEach(key => {
        if (res[key]) {
          res[key] = res[key][lang]
        }
      })
    }

    // also check relations if they have translations
    Object.keys(res).forEach(key => {
      if (Array.isArray(res[key])) {
        res[key] = applyLang(res[key], lang)
      }
      // if its model, then try to translate it too
      if (res[key] && res[key].modelName) {
        res[key] = applyLangSingle(res[key], lang)
      }
    })

    return res
  })

const applyLangSingle = (o, lang, modelNameArg) => {
  const res = Object.assign({}, o)
  const modelName = modelNameArg || res.modelName

  if (modelsFields[modelName]) {
    modelsFields[modelName].forEach(key => {
      if (res[key]) {
        res[key] = res[key][lang]
      }
    })
  }

  // also check relations if they have translations
  Object.keys(res).forEach(key => {
    if (Array.isArray(res[key])) {
      res[key] = applyLang(res[key], lang)
    }

    // if its model, then try to translate it too
    if (res[key] && res[key].modelName) {
      res[key] = applyLangSingle(res[key], lang)
    }
  })

  return res
}

// needs to be old fashioned
// eslint-disable-next-line no-multi-assign
module.exports = exports = {
  languages,
  defaultLanguage,
  messages,
  findKey,
  translatePath,
  prefixPath,
  t,
  applyLang,
  applyLangSingle,
  getLangByCountry,
}
