/**
 * This provides this page language settings and helpers to any component
 *
 */

import React from "react"
import { t, languages, defaultLanguage, translatePath } from "."
import IntlContext from "./IntlContext"

export default WrappedComponent => {
  return class extends React.Component {
    static contextType = IntlContext

    translateInThisLang = (key, lang = this.context.language) => {
      return t(key, lang)
    }

    render() {
      const injectedProps = {
        // helper translate fn
        t: this.translateInThisLang,
        // this lang app config
        languages,
        defaultLanguage,
        translatePath,
        // intl context, with provider state and gatsby-node page lang settings
        ...this.context,
      }

      return <WrappedComponent {...this.props} intl={injectedProps} />
    }
  }
}
