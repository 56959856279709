import React from "react"
import styled, { css } from "styled-components"
import { addResponsivity } from "../lib/styles"

// Visibility and Responsivity helper
const VH = styled.div`
  ${addResponsivity()}

  ${({ show, displayAs = "block" }) => css`
    display: ${show ? displayAs : "none"};
  `}
`

export default VH
