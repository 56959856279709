import React from "react"
import PropTypes from "prop-types"
import Input from "./Input"
import Text from "./Text"
import Gap from "./Gap"
import Col from "./Col"

const TextField = ({ label, hasError, error, ...props }) => (
  <Col>
    {error || hasError ? (
      <Text.Error>{error || label}</Text.Error>
    ) : (
      <Text>{label}</Text>
    )}
    <Gap gap="4px" />
    <Input hasError={error || hasError} {...props} />
  </Col>
)

TextField.defaultProps = {
  hasError: false,
  label: "",
  error: "",
}

TextField.propTypes = {
  hasError: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string,
}

export default TextField
