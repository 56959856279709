import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"

import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { addResponsivity, up, down, between } from "../lib/styles"
import {
  Col,
  Row,
  Text,
  Title,
  Gap,
  DashedLine,
  Link,
  WaveImage,
  Listing,
  RH,
} from "."
import T from "../lib/intl/T"
import {
  extractNodes,
  extractNodesFromData,
  extractNodesFromDataApplyLangs,
  createAnchor,
} from "../lib/helpers"
import injectIntl from "../lib/intl/injectIntl"
import hexagonPath from "../data/images/hexagon-small.svg"

const VISIBLE_BP = 740
const MENU_VISIBLE_BP = "menu"
const DIVIDER_BP = 1025

const NewLabelWrapper = styled.div`
  position: relative;
  width: 50px;
  height: 38px;
  div {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  p {
    color: #ffffff;
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    text-transform: uppercase;
  }
`

const NewLabel = ({ children }) => (
  <NewLabelWrapper>
    <img src={hexagonPath} />
    <div>
      <p>{children}</p>
    </div>
  </NewLabelWrapper>
)

class ProcedureCategories extends Component {
  render() {
    const {
      data,
      intl: { language, t },
      isMenu = false,
    } = this.props

    let { procedureCategories } = extractNodesFromDataApplyLangs(data, language)

    const categories = [
      ...procedureCategories,
      {
        name: t("Kombinace zákroků"),
        iconImage: "combination.svg",
      },
    ].map(c => ({
      ...c,
      anchor: createAnchor(c.name),
      iconImagePath: require(`../data/images/categories-icons/${c.iconImage}`).default,
      procedures: c.procedures || [],
    }))

    procedureCategories = categories.slice(0, categories.length - 1)
    const chirurgyCategories = categories.filter(
      ({ isOrthopedics }) => !isOrthopedics
    )
    const orthopedicsCategories = categories.filter(
      ({ isOrthopedics }) => isOrthopedics
    )

    procedureCategories.forEach(c => {
      c.procedures.sort((a, b) => a.sortOrder - b.sortOrder)
    })

    const renderMenuCategory = (cat, index) => (
      <React.Fragment key={cat.anchor}>
        {index !== 0 && (
          <Gap.Fluid min={isMenu ? "40px" : "26px"} max="42px" onlyWidth />
        )}
        <Link.BasicLink asA href={`#${cat.anchor}`}>
          <Row
            position="relative"
            alignItems="center"
            height={isMenu ? "32px" : "56px"}
          >
            <img
              src={cat.iconImagePath}
              alt={`${cat.name} category icon`}
              style={{ maxHeight: "100%", maxWidth: isMenu ? "32px" : "unset" }}
            />
            <Gap gap="12px" />
            {cat.name}
          </Row>
        </Link.BasicLink>
      </React.Fragment>
    )

    return (
      <RH showAfter={isMenu ? MENU_VISIBLE_BP : VISIBLE_BP}>
        <Row
          width="100%"
          alignItems="flex-end"
          mobileAlignItems="flex-start"
          responsive
          bp={isMenu ? 0 : DIVIDER_BP}
        >
          <Col>
            {!isMenu && (
              <>
                <Title.SmallerTitle>
                  <T>Plastická chirurgie</T>
                </Title.SmallerTitle>
                <Gap gap="24px" />
              </>
            )}
            <Row>
              {chirurgyCategories.map((cat, index) =>
                renderMenuCategory(cat, index)
              )}
            </Row>
          </Col>
          <Gap
            gap={isMenu ? "40px" : "56px"}
            mobileGap="15px"
            bp={DIVIDER_BP}
            onlyWidth
          />
          <RH showAfter={DIVIDER_BP}>
            <DashedLine
              isVertical
              height={isMenu ? "32px" : "48px"}
              style={{
                position: "relative",
                top: `${isMenu ? "-3px" : "-10px"}`,
              }}
            />
          </RH>
          <Gap
            gap={isMenu ? "40px" : "56px"}
            mobileGap="15px"
            bp={DIVIDER_BP}
            onlyWidth
          />
          <Col>
            {!isMenu && (
              <Row alignItems="center">
                <Title.SmallerTitle>
                  <T>Dermatologie</T>
                </Title.SmallerTitle>
                {/* <Gap gap="6px" />
                <NewLabel>
                  <T>nově</T>
                </NewLabel> */}
              </Row>
            )}
            {!isMenu && <Gap gap="20px" />}
            <Row>
              {orthopedicsCategories.map((cat, index) =>
                renderMenuCategory(cat, index)
              )}
            </Row>
          </Col>
        </Row>
        {!isMenu ? (
          <>
            <Gap gap="45px" />
            <DashedLine />
          </>
        ) : (
          <Gap gap="12px" />
        )}
      </RH>
    )
  }
}

const WithQuery = props => (
  <StaticQuery
    query={query}
    render={queryResult => (
      <ProcedureCategories {...props} data={queryResult} />
    )}
  />
)

export default injectIntl(WithQuery)

const query = graphql`
  query {
    procedureCategories: allProcedurecategories(
      sort: { fields: sortOrder, order: ASC }
    ) {
      edges {
        node {
          ...ProcedureCategoryFragment
        }
      }
    }
  }
`
