import React, { Component } from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { GatsbyImage } from "gatsby-plugin-image"
import styled, { css } from "styled-components"
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock"
import { useStaticQuery, graphql } from "gatsby"
import { addResponsivity, up, down, between } from "../lib/styles"
import { Col, Row, Text, Title, Gap, Link, Button } from "."
import T from "../lib/intl/T"
import injectIntl from "../lib/intl/injectIntl"
import { extractNodesFromDataApplyLangs } from "../lib/helpers"
import logoPath from "../data/images/logo-forme.svg"

import popupCloseIconPath from "../data/images/popup-close-icon.svg"

const StyledImg = styled(GatsbyImage)`
  max-width: 100%;
  img {
    object-fit: contain !important;
  }
`

const Wrapper = styled.div`
  ${({ theme: { colors }, contentEnded }) => css`
    background: white;
    width: 100%;
    max-width: calc(100vw - 8px);
    /* max-height: calc(100vh - 8px); */
    max-height: calc(100vh - 100px);
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 1000001;
    border: solid 1px ${colors.green};
    border-radius: 32px;
    transform: translate(-50%, -50%);
    box-shadow: 0 8px 24px 0 rgba(0, 176, 162, 0.12);
    overflow: hidden;
    /* display: flex; */

    /* margin-top: 50px; */

    ${up(908)} {
      max-width: 900px;
    }

    &:before {
      content: "";
      width: 100%;
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 95%
      );
      position: absolute;
      top: 0;
      left: 0;
      height: 80px;
      display: block;
      pointer-events: none;
      z-index: 1;
    }
    &:after {
      content: "";
      width: 100%;
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      position: absolute;
      bottom: 0;
      left: 0;
      height: 80px;
      display: ${contentEnded ? "none" : "block"};
      pointer-events: none;
    }
  `}
`

const Content = styled.div`
  ${({ contentEnded }) => css`
    max-width: 100%;
    max-height: 100%;
    overflow-y: auto;
    position: relative;
    text-align: center;
    flex-direction: column;
    display: flex;
    align-items: center;

    /* max-width: calc(100vw - 8px); */
    max-height: calc(100vh - 100px);

    padding: 56px 40px 40px;
    ${up("mobile")} {
      padding: 80px 64px 40px;
    }
    ${up(908)} {
      padding: 96px 125px 56px;
    }
  `}
`

const Overlay = styled.div`
  ${() => css`
    background: rgba(255, 255, 255, 0.88);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000000;
  `}
`

const CloseIcon = styled.img`
  ${() => css`
    width: 26px;
    height: 26px;
    top: 24px;
    right: 24px;
    position: absolute;
    cursor: pointer;
    z-index: 2;
    ${up("mobile")} {
      top: 44px;
      right: 42px;
    }
  `}
`

export const ConfirmPopup = injectIntl(({ hideModal, intl: { t } }) => (
  <Overlay onClick={hideModal}>
    <Wrapper onClick={e => e.stopPropagation()}>
      <CloseIcon src={popupCloseIconPath} onClick={() => hideModal()} />
      <Content>
        <Text.Intro mobileFontSize="16px" mobileLineHeight="24px">
          {t(
            "Velice nás těší Váš zájem o osobní konzultaci ve Formé clinic. Nejpozději do dvou pracovních dnů se Vám ozveme a domluvíme společně termín konzultace, který Vám bude nejvíce vyhovovat. Těšíme se na Vás."
          )}
        </Text.Intro>
        <Gap gap="14px" />
        <Text.Intro mobileFontSize="16px" mobileLineHeight="24px">
          {t("Váš tým Formé clinic")}
        </Text.Intro>
        <Gap gap="22px" />
        <img src={logoPath} />
        <Gap gap="22px" />

        {/* <Col alignItems="center">
          <Button onClick={() => hideModal()}>Zavřít</Button>
          <Gap gap="8px" hideAfter="mobile" />
        </Col> */}
      </Content>
    </Wrapper>
  </Overlay>
))

class AllPopups extends Component {
  state = {
    popupClosed: null,
    contentEnded: false,
    popup: null,
  }

  componentDidMount() {
    try {
      const popupClosed = window.sessionStorage.getItem("popupClosed")
      this.setState({ popupClosed })

      setTimeout(() => {
        this.pickPopup()
        this.contentScrolled()
      }, 100)
    } catch (error) {
      // console.log('e', error)
    }
  }

  pickPopup = () => {
    // filter the shit
    const { popups, intl } = this.props
    const { popupClosed } = this.state

    const popup = popups.filter(
      p =>
        moment(p.validFrom).isBefore(moment()) &&
        moment(p.validTo).isAfter(moment()) &&
        p.lang === intl.language
    )[0]

    this.setState({ popup }, () => {
      // scroll lock body
      if (popup && popup._id && popup._id !== popupClosed) {
        // enableBodyScroll(this.contentRef)
        if (this.contentRef) {
          this.contentRef.addEventListener("scroll", this.contentScrolled)
        }
      }
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      intl: { language: prevLang },
    } = prevProps
    const {
      intl: { language },
    } = this.props

    if (language !== prevLang) {
      this.pickPopup()
    }
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks()
    this.contentRef?.removeEventListener("scroll", this.contentScrolled)
  }

  close = id => {
    this.setState({ popupClosed: id })
    window.sessionStorage.setItem("popupClosed", id)
    clearAllBodyScrollLocks()
  }

  contentScrolled = () => {
    if (this.contentRef) {
      if (
        this.contentRef.offsetHeight + this.contentRef.scrollTop >=
        this.contentRef.scrollHeight
      ) {
        this.setState({ contentEnded: true })
      } else {
        this.setState({ contentEnded: false })
      }
    }
  }

  render() {
    const { popupClosed, contentEnded, popup } = this.state

    // no popup
    if (!popup || !popup._id) {
      return null
    }

    // user already closed this popup
    if (popup && popup._id === popupClosed) {
      return null
    }

    const isExternal = popup.buttonLink?.startsWith("http")

    return (
      <Overlay>
        <Wrapper contentEnded={contentEnded}>
          <CloseIcon
            src={popupCloseIconPath}
            onClick={() => this.close(popup._id)}
          />
          <Content
            ref={ref => {
              this.contentRef = ref
            }}
          >
            {popup.image && popup.image && (
              <>
                <StyledImg image={popup.image.filename.optimized.gatsbyImageData} />
                <Gap gap="32px" />
              </>
            )}
            <Title.MediumTitle responsive>{popup.title}</Title.MediumTitle>
            <Gap gap="16px" />
            <Text.Intro mobileFontSize="16px" mobileLineHeight="24px">
              {popup.text}
            </Text.Intro>
            <Gap gap="32px" />
            <Col alignItems="center">
              <Button.Link
                onClick={() => this.close(popup._id)}
                to={!isExternal && popup.buttonLink}
                asA={isExternal}
                href={isExternal && popup.buttonLink}
                target={isExternal && "_blank"}
              >
                {popup.buttonText}
              </Button.Link>
              <Gap gap="8px" hideAfter="mobile" />
            </Col>
          </Content>
        </Wrapper>
      </Overlay>
    )
  }
}

const QueryComponent = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query {
      popups: allPopups {
        edges {
          node {
            ...PopupFragment
          }
        }
      }
    }
  `)

  const { popups } = extractNodesFromDataApplyLangs(data, intl.language)

  return <AllPopups popups={popups} intl={intl} />
}

export default injectIntl(QueryComponent)
