import React from 'react'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import styled, { css } from 'styled-components'
import { Link, RH, Row, VH, Col, withScrollDetection, DashedLine } from '.'
import { fadeInFromNone, menuFadeIn, menuFadeOut } from '../lib/animations'
import { down, up, addResponsivity, between, theme } from '../lib/styles'
import injectIntl from '../lib/intl/injectIntl'
import T from '../lib/intl/T'
import { PHONE_NUMBER, PHONE_NUMBER_EN, PHONE_NUMBER_EN_PREFIX } from '../lib/config'

import Gap from './Gap'
import pagesConfig from '../../pagesConfig'

// assets
import logoPath from '../data/images/logo-forme.svg'
import openMenuPath from '../data/images/icon-open-menu.svg'
import closeMenuPath from '../data/images/icon-close-menu.svg'
import iconPhonePath from '../data/images/icon-phone-menu.svg'
import iconCaretPath from '../data/images/icon-menu-caret.svg'
import phoneIconPath from '../data/images/hp-phone-icon.svg'
import hexagonIconPath from '../data/images/hexagon-icon.svg'
import ProcedureCategories from './ProcedureCategories'
import { links } from '../lib/links'

const PhoneLink = styled.a`
  ${({ hidden }) => css`
    display: ${hidden ? 'none' : 'block'};
    margin: 11px 0 0;
  `}
`

const NavLink = styled(Link.NavLink)`
  ${({ theme: { colors }, isOpen, isPinned, animationDelay = 0.1 }) => css`
    display: block;
    transition: 0.3s;

    padding: 12px 0;
    ${up('menu')} {
      padding: 48px 8px;
    }
    ${up(1326)} {
      padding: 48px 16px;
    }

    /* &:last-child {
      padding-right: 0;
    } */

    ${isPinned &&
    css`
      ${up('menu')} {
        padding: 30px 8px;
      }
      ${up(1326)} {
        padding: 30px 16px;
      }
    `}

    ${isOpen &&
    css`
      opacity: 0;
      animation-name: ${fadeInFromNone};
      animation-duration: 0.3s;
      animation-delay: ${animationDelay}s;
      animation-fill-mode: forwards;
    `}
    ${up('menu')} {
      opacity: 1;
    }
    & > a {
    }
  `}
`
const Wrapper = styled.div`
  ${({
    theme: {
      sizes: { maxPageWidth },
    },
    isPinned,
    isOpen,
    scrollDirection,
    scrollPosition,
    height,
  }) => css`
    max-width: ${maxPageWidth}px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.3s;
    transition-property: height, box-shadow, transform;
    z-index: 4;
    position: relative;
    margin: 0 auto;
    padding: 0 10px;
    /* box-shadow: ${!isOpen ? '0 2px 16px rgba(64, 82, 171, 0.16)' : ''}; */
    background: transparent;
    ${up('menu')} {
      padding: 0 24px;
      transition-property: height, box-shadow;
    }
    ${!isOpen &&
    css`
      border-radius: 0 0 16px 16px;
    `};

    ${isOpen &&
    css`
      background: white;
      ${up('menu')} {
        background: none;
      }
    `};

    ${isPinned &&
    css`
      box-shadow: ${!isOpen ? '0 2px 16px rgba(64, 82, 171, 0.16)' : ''};
      position: fixed;
      top: 0;
      left: 0;
      background: white;

      ${up('menu')} {
        left: 50%;
        transform: translateX(-50%);
      }
    `}

    ${isPinned &&
    !isOpen &&
    css`
      /* top: ${scrollDirection === 'down' && scrollPosition > 200 ? -height : 0}px; */
      /* varianta bez covid lišty -> transform: translateY(${scrollDirection === 'down' &&
      scrollPosition > 200
        ? -100
        : 0}% ); */
      transform: translateY(${scrollDirection === 'down' && scrollPosition > 440 ? -100 : 0}%);
      ${up('menu')} {
        transform: translateX(-50%);
        /* top: 0; */
      }
    `}
  `}
`
const Container = styled.div`
  ${({ theme: { colors }, isPinned }) => css`
    max-width: 1280px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    align-items: flex-start;
    display: flex;

    height: 80px;
    ${up('menu')} {
      height: auto;
    }
    ${between('mobile', 'menu')} {
      margin-left: -4px;
    }
  `}
`
const DottedLine = styled.div`
  ${({ theme: { colors }, margin }) => css`
    ${addResponsivity()}

    display: block;
    margin: ${margin};
    width: 100%;
    width: calc(100% + 4px);
    height: 2px;
    position: relative;

    background-image: linear-gradient(to right, ${colors.green} 7px, rgba(255, 255, 255, 0) 0%);
    background-position: bottom left;
    background-size: 11px 100px;
    background-repeat: repeat-x;
  `}
`
const DropDown = styled.div`
  ${({ theme: { colors }, isOpen }) => css`
    flex-direction: column;
    z-index: 1;
    align-items: center;
    position: absolute;
    top: 50%;
    background: white;
    border-radius: 4px;
    margin: 16px 0px;
    box-shadow: 0 2px 8px rgba(64, 82, 171, 0.16);

    display: ${isOpen ? 'flex' : 'none'};

    ${up(1326)} {
      margin: 16px 8px;
    }
  `}
`
const CallUsWrapper = styled.div`
  ${addResponsivity()}
  ${({ isMobile }) =>
    isMobile &&
    css`
      width: 100%;
    `}
`

const MobileLangsWrapper = styled.div`
  ${addResponsivity()}
  width: 100%;
  ${({ isMobile }) => isMobile && css``}
`

const HexaLang = styled.div`
  width: 50px;
  height: 57px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${({ isSelected }) =>
    isSelected &&
    css`
      background: url(${hexagonIconPath});
      background-repeat: no-repeat;
      background-position: center center;
      color: white;
    `}
`
const DropDownWrapper = styled.div`
  position: relative;
  display: none;
  ${up('menu')} {
    display: block;
  }
`
const LangLink = styled(Link.NavLink)`
  padding: 8px 12px;
`
const CaretIcon = styled.img`
  ${({ up }) => css`
    transform: rotate(${up ? 180 : 0}deg);
  `}
`
const Logo = styled.img`
  ${({ isPinned, smallerBp }) => css`
    /* page padding is 24px, but logo is 16px */
    /* margin-left: -${24 - 16}px; */
    display: block;
    width: 175px;
    height: 40px;
    margin-top: 20px;
    transition: 0.3s;

    ${up('menu')} {
      margin-top: ${isPinned ? 20 : 32}px;
    }

    ${up(smallerBp)} {
      ${!isPinned &&
      css`
        height: 46px;
        width: 201px;
      `}
    }
  `}
`

const ProceduresMenu = styled.div`
  ${({ isOpen }) => css`
    height: ${isOpen ? '67px' : 0};
    overflow: hidden;
    transition: 0.3s;
    width: 100%;
    display: none;
    ${up('menu')} {
      display: block;
    }
  `}
`

const NavList = styled.div`
  ${({ theme: { sizes, colors }, isOpen }) => css`
    display: ${isOpen ? 'flex' : 'none'};
    flex-direction: column;
    position: absolute;
    align-items: flex-start;
    top: 80px;
    padding: 16px 0 0 40px;
    background: white;
    width: 100%;
    /* we want scroll when low screen height */
    max-height: calc(100vh - 80px);
    overflow-y: auto;
    overflow-x: hidden;
    left: 0;
    height: 100vh;

    ${up('menu')} {
      left: auto;
      height: auto;
      background: none;
      flex-direction: row;
      display: flex;
      position: initial;
      padding: 0;
      width: auto;
      overflow-y: visible;
      overflow-x: visible;
      /* align-items: center; */
    }
  `}
`

const Burger = styled.div`
  ${({ theme: { sizes }, isOpen }) => css`
    cursor: pointer;
    z-index: 1;
    /* padding: 4px; */
    margin: 16px 0 0;
    position: relative;
    /* height: 32px; */
    height: 48px;
    width: 48px;

    ${up('menu')} {
      display: none;
    }
  `}
`

const BurgerIcon = styled.img`
  ${({ theme: { sizes }, isOpen, animationDelay = 0.1 }) => css`
    display: block;
    /* padding: 35px 0 20px; */
    top: 50%;
    left: 50%;
    position: relative;
    transform: translate(-50%, -50%);
    /* opacity: 0; */
    /* animation-name: ${isOpen ? fadeInFromNone : fadeInFromNone};
    animation-duration: 0.5s;
    animation-delay: ${animationDelay}s;
    animation-fill-mode: forwards; */
  `}
`

const CallUs = styled.div`
  ${({ theme: { colors }, smallerBp, isPinned }) => css`
    ${addResponsivity({ displayAs: 'flex' })}
    color: ${colors.green};
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    flex-direction: column;
    align-items: flex-start;
    /* padding: 29px 0 0; */
    width: 100%;
    /* order: -1; */
    transition: 0.3s;

    & a {
      font-family: Signika;
      font-weight: 300;

      font-size: 24px;
      line-height: 32px;

      & span {
        font-weight: 700;
      }
    }

    ${up('menu')} {
      /* order: 0; */
      align-items: flex-end;
      padding-left: 12px;
      /* padding: 29px 0 0 12px; */
      padding-top: ${isPinned ? '24px' : '29px'};
      width: auto;

      & a {
        font-size: 18px;
        line-height: 32px;

        & span {
        }
      }
    }
    ${up(smallerBp)} {
      padding-top: 25px;

      & a {
        font-size: 24px;
        line-height: 32px;
      }
    }
  `}
`

const LightSpan = styled.span`
  font-weight: 300 !important;
`

const menuLinks = [
  {
    to: Link.ABOUT,
    text: 'O klinice',
  },
  {
    to: Link.TEAM,
    text: 'Náš tým',
  },
  {
    to: Link.PROCEDURES,
    text: 'Zákroky',
  },
  {
    to: Link.CHANGES,
    text: 'Před a po',
  },
  {
    to: Link.REFERENCES,
    text: 'RECENZE',
  },
  {
    to: Link.FOUNDATION,
    text: 'Nadace',
  },
  {
    to: Link.BLOG,
    text: 'Blog',
  },
  {
    to: Link.CONTACT,
    text: 'Kontakt',
  },
]

const getMenuLinks = lang =>
  menuLinks
    .filter(link => lang !== 'de' || link.to !== Link.FOUNDATION) // DE has no foundation
    .filter(link => lang !== 'cs' || link.to !== Link.FOUNDATION) // CS has blog instead of foundation
    .filter(link => lang === 'cs' || link.to !== Link.BLOG)
    .filter(l => lang === 'cs' || l.to !== Link.CHANGES) // only cs has changes

class Menu extends React.Component {
  state = {
    isOpen: false,
    isLangOpen: false,
    isPinned: false,
    isProceduresMenuShow: false,
    height: 0,
  }

  targetRef = React.createRef()

  targetElement = null

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.handleResize)
    const height = this.wrapperRef.clientHeight
    this.setState({ height })
    this.targetElement = this.targetRef.current
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.handleResize)
    clearAllBodyScrollLocks()
  }

  handleResize = () => {
    if (window.innerWidth > theme.breakpoints.menu) {
      this.closeMenu()
    }
  }

  // pouze kvůli covid baru
  getPinnedHeight = () => {
    const { pinned, bp1, bp2, bp3, pinned1, pinned2, pinned3 } = theme.covidBar
    const width = window.innerWidth

    let result = pinned
    if (width < bp1) result = pinned1
    if (width < bp2) result = pinned2
    if (width < bp3) result = pinned3

    return result
  }

  handleScroll = () => {
    const { isProceduresMenuShow: isShow } = this.state
    let { height } = this.state

    // varianta bez covid baru -> const pinnedHeight = 0
    const pinnedHeight = this.getPinnedHeight()

    const isPinned = window.scrollY > pinnedHeight

    let isProceduresMenuShow
    if (isShow) {
      isProceduresMenuShow = window.scrollY > 450
    } else {
      isProceduresMenuShow = window.scrollY > 500
    }
    if (window.scrollY) {
      height = this.wrapperRef.clientHeight
    }

    this.setState({ isPinned, isProceduresMenuShow, height })
  }

  toggleMenu = () => {
    const { isOpen } = this.state
    if (isOpen) {
      enableBodyScroll(this.targetElement)
    } else {
      this.wrapperRef.scrollIntoView()
      disableBodyScroll(this.targetElement)
    }

    this.setState({ isOpen: !isOpen })
  }

  closeMenu = () => {
    this.setState({ isOpen: false })
    enableBodyScroll(this.targetElement)
  }

  toggleLangMenu = () => {
    this.setState(({ isLangOpen }) => ({ isLangOpen: !isLangOpen }))
  }

  closeLangMenu = () => {
    this.setState({ isLangOpen: false })
  }

  isLinkActive(link) {
    const {
      pathname,
      intl: { languages },
    } = this.props
    if (!pathname) return false
    const paths = languages.map(l => Link.translatePath(link, l))
    return paths.some(path => path === pathname || `${path}/` === pathname)
  }

  isProcedurePage = () => {
    const {
      pathname,
      intl: { languages, language },
    } = this.props
    if (!pathname) return false
    const procedurePath = Link.translatePath(links.PROCEDURES, language)

    return pathname === procedurePath
  }

  getThisPagePropsForTranslation = lang => {
    let {
      intl: { slugs, pathKey, onlyLangs, pageKey },
    } = this.props

    // const pageConf = pagesConfig[pageKey] || {}

    // console.log('slugs, pathKey, onlyLangs, pageKey', slugs, pathKey, onlyLangs, pageKey)
    // combine pathKey (it's actually root key) with custom slug
    const slug = (slugs && slugs[lang]) || false
    pathKey = slug ? `${pathKey !== '/' ? pathKey : ''}/${slug}` : pathKey

    // console.log('adadasdas', pathKey, pageKey, onlyLangs, slugs, pathKey, onlyLangs, pageKey)

    return {
      to: pathKey,
      pageKey,
      onlyLangs,
    }
  }

  render() {
    const { isOpen, isLangOpen, isPinned, isProceduresMenuShow, height } = this.state
    const {
      intl: { language, languages },
      scrollDirection,
      scrollPosition,
    } = this.props

    const showProceduresMenu = isProceduresMenuShow && this.isProcedurePage()

    const friendlyCodes = {
      cs: 'CZ',
      en: 'EN',
      de: 'DE',
    }

    const hideCallUsAfter = language === 'cs' ? 1140 : 1190
    const mobileBp = language === 'cs' ? 'menu' : 1000
    const smallerCallUsBp = language === 'cs' ? 1184 : 30000
    const smallerLogoBp = language === 'cs' ? 990 : 1045

    // const phoneNumber = language === 'en' ? PHONE_NUMBER_EN : PHONE_NUMBER
    // const phonePrefix = language === 'en' ? PHONE_NUMBER_EN_PREFIX : '+420'

    const phoneNumber = PHONE_NUMBER
    const phonePrefix = '+420'

    const CallUsComponent = ({ mobile }) => (
      <CallUsWrapper
        isMobile={mobile}
        hideAfter={mobile ? 'menu' : undefined}
        showAfter={!mobile ? hideCallUsAfter : undefined}
      >
        <CallUs smallerBp={smallerCallUsBp} isPinned={isPinned}>
          <DottedLine hideAfter="menu" margin="0 0 16px" />
          <Row alignItems="center">
            <RH hideAfter="menu">
              <img src={iconPhonePath} alt="" />
            </RH>
            <Gap gap="17px" hideAfter="menu" />
            <Col mobileAlignItems="flex-start" alignItems="flex-end" bp="menu">
              <VH show={!isPinned || mobile}>
                <T>Volejte</T>
              </VH>
              <a href={`tel:${phonePrefix}${phoneNumber}`}>
                <LightSpan>{phonePrefix}</LightSpan> <span>{phoneNumber}</span>
              </a>
            </Col>
          </Row>
          <DottedLine hideAfter="menu" margin="14px 0 0" />
          <Gap gap="12px" hideAfter="menu" />
        </CallUs>
      </CallUsWrapper>
    )

    return (
      <>
        {isPinned && <Gap gap={`${height}px`} />}
        <Wrapper
          isPinned={isPinned}
          isOpen={isOpen}
          ref={ref => {
            this.wrapperRef = ref
          }}
          scrollDirection={scrollDirection}
          scrollPosition={scrollPosition}
          height={height}
        >
          <Container>
            <Row width="100%" justifyContent="space-between">
              <div>
                <Link to="/" onClick={this.closeMenu}>
                  <Logo src={logoPath} isPinned={isPinned} smallerBp={smallerLogoBp} />
                </Link>
              </div>

              <NavList isOpen={isOpen} ref={this.targetRef}>
                <CallUsComponent mobile />
                {getMenuLinks(language).map((l, index) => (
                  <NavLink
                    isOpen={isOpen}
                    key={l.to}
                    to={l.to}
                    isActive={this.isLinkActive(l.to)}
                    onClick={this.closeMenu}
                    animationDelay={0.04 * index}
                    isPinned={isPinned}
                  >
                    <T>{l.text}</T>
                  </NavLink>
                ))}

                <MobileLangsWrapper hideAfter="menu">
                  <Col width="100%">
                    <DottedLine margin="12px 0 0" />
                    <Row width="100%" position="relative">
                      {languages.map((l, index) => (
                        <React.Fragment key={l}>
                          <NavLink
                            animationDelay={menuLinks.length * 0.04}
                            key={l}
                            isOpen={isOpen}
                            onClick={() => {
                              this.closeMenu()
                              this.closeLangMenu()
                            }}
                            // asA={l === 'de'}
                            // href="/de"
                            preferLang={l}
                            {...this.getThisPagePropsForTranslation(l)}
                          >
                            <HexaLang isSelected={language === l}>{friendlyCodes[l]}</HexaLang>
                          </NavLink>
                          <Gap gap="8px" />
                        </React.Fragment>
                      ))}
                    </Row>
                    <Gap gap="80px" />
                  </Col>
                </MobileLangsWrapper>

                <DropDownWrapper>
                  <NavLink
                    animationDelay={menuLinks.length * 0.04}
                    asSpan
                    onClick={this.toggleLangMenu}
                    isPinned={isPinned}
                    isOpen={isOpen}
                  >
                    <Row>
                      <div>{friendlyCodes[language]}</div>
                      <Gap gap="4px" />
                      <CaretIcon up={isLangOpen} src={iconCaretPath} />
                    </Row>
                  </NavLink>

                  <DropDown isOpen={isLangOpen}>
                    {languages.map(l => (
                      <LangLink
                        key={l}
                        onClick={() => {
                          this.closeMenu()
                          this.closeLangMenu()
                        }}
                        // asA={l === 'de'}
                        // href="/de"
                        preferLang={l}
                        {...this.getThisPagePropsForTranslation(l)}
                      >
                        {friendlyCodes[l]}
                      </LangLink>
                    ))}
                  </DropDown>
                </DropDownWrapper>

                <CallUsComponent />
              </NavList>

              <Row justifyContent="flex-end" grow="10" hideAfter="menu">
                <Gap.Fluid min="10px" max="10px" grow="1" />
                <Burger isOpen={isOpen} onClick={this.toggleMenu}>
                  {isOpen ? <BurgerIcon src={closeMenuPath} /> : <BurgerIcon src={openMenuPath} />}
                </Burger>
                <Gap.Fluid min="10px" max="20px" grow="100" hidden={isOpen} />
                <PhoneLink href={`tel:${phonePrefix}${phoneNumber}`} hidden={isOpen}>
                  <img src={phoneIconPath} alt="" />
                </PhoneLink>
              </Row>
            </Row>

            <ProceduresMenu isOpen={showProceduresMenu && isPinned}>
              <DashedLine />
              <Gap gap="16px" />
              <Row alignItems="center" justifyContent="center" width="100%">
                <Col width="1160px" maxWidth="100%" justifyContent="flex-start">
                  <ProcedureCategories isMenu />
                </Col>
              </Row>
            </ProceduresMenu>
          </Container>
        </Wrapper>
      </>
    )
  }
}

export default withScrollDetection(injectIntl(Menu))
