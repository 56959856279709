import { useStaticQuery, graphql } from 'gatsby'
import React from 'react'
import moment from 'moment'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import Text from './Text'
import Row from './Row'
import Col from './Col'
import Gap from './Gap'
import Link from './Link'
import VH from './VH'
import { FacebookIcon, InstagramIcon, YoutubeIcon } from './icons'
import CallMeForm from './CallMeForm'
import ContactItem from './ContactItem'
import { up, addPagePadding } from '../lib/styles'
import T from '../lib/intl/T'
import injectIntl from '../lib/intl/injectIntl'
import benefitCardImage from '../data/images/benefit-cards-footer.png'
import payLaterIconPath from '../data/images/procedures/icon-4.svg'

const ColGap = () => <Gap gap="16px" />
const TitleGap = () => <Gap gap="24px" />

const BenefitCards = styled.div`
  img {
    width: 200px;
    max-width: 100%;
  }
`

const Wrapper = styled.div`
  ${({ theme: { pageSidePadding, mobilePageSidePadding, breakpoints } }) => css`
    background-color: #404e5b;
    width: 100%;
    padding: 56px ${mobilePageSidePadding} 14px;
    ${up(breakpoints.mobile)} {
      padding: 56px ${pageSidePadding} 14px;
    }
  `}
`

const ContentWrapper = styled.div`
  ${({ theme }) => css`
    max-width: 1086px;
    margin: auto;
  `}
`

const IconWrapper = styled.div`
  ${({ theme }) => css`
    margin-right: 16px;
  `}
`

const FooterText = styled(Text)`
  ${({ theme: { colors }, fontWeight }) => css`
    color: ${colors.white};
    font-size: 16px;
    font-weight: ${fontWeight || 400};
    line-height: 24px;
  `}
`

const FooterTitle = styled.div`
  ${({ theme: { fonts, colors } }) => css`
    color: ${colors.white};
    font-family: ${fonts.muli};
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    /* margin-bottom: 24px; */
  `}
`

const FooterCol = styled(Col)`
  ${({ theme: { breakpoints } }) => css`
    flex-basis: 100%;
    ${up(breakpoints.mobile)} {
      flex-basis: 50%;
      padding-right: 24px;
    }
    ${up(breakpoints.menu)} {
      flex-basis: auto;
    }
    &:last-child {
      padding-right: 0;
      ${up(breakpoints.mobile)} {
        padding-right: 0;
      }
    }
  `}
`

const FooterRow = styled(Row)`
  ${({ theme: { breakpoints } }) => css`
    justify-content: space-between;
    flex-wrap: wrap;
    ${up(breakpoints.menu)} {
      flex-wrap: nowrap;
    }
  `}
`

const SocialLink = styled.a.attrs(() => ({
  target: '_blank',
}))`
  ${({ theme }) => css`
    cursor: pointer;
    transition: 0.2s;
    &:hover {
      path {
        fill: #d6fbf9;
      }
    }
  `}
`

const ThanksMessage = () => (
  <FooterTitle>
    <T raw>{'Děkujeme,<br />ozveme se vám obratem.'}</T>
  </FooterTitle>
)
const { PageLinkComponent } = Link

const PayLaterBlock = () => (
  <Col maxWidth="260px">
    <Row>
      <img src={payLaterIconPath} alt="" style={{ width: '48px', height: '48px' }} />
      <Gap gap="16px" />

      <FooterTitle>
        <T raw>Dopřejte si změnu hned a plaťte pak!</T>
      </FooterTitle>
    </Row>
    <TitleGap />
    <FooterText>
      <T>Nově nabízíme možnost rozložit si cenu zákroku až do 24 měsíčních plateb bez navýšení.</T>
    </FooterText>
  </Col>
)

const SocialsBlock = () => (
  <>
    <FooterTitle>
      <T>Sledujte nás</T>
    </FooterTitle>
    <Gap gap="12px" />
    <Row>
      <SocialLink href="https://www.facebook.com/FormeClinicPrague/?ref=hl">
        <FacebookIcon />
      </SocialLink>
      <Gap gap="16px" />
      <SocialLink href="https://www.instagram.com/formeclinic/">
        <InstagramIcon />
      </SocialLink>
      <Gap gap="16px" />
      <SocialLink href="https://www.youtube.com/channel/UCy_qFEJICML-qNVhU1D6V2A">
        <YoutubeIcon />
      </SocialLink>
    </Row>
  </>
)

const LinksBlock = props => (
  <>
    <Link.FooterLink to={Link.FAQ}>
      <T>Časté dotazy</T>
    </Link.FooterLink>
    <Gap gap="10px" />
    {props.intl.language !== 'de' && (
      <>
        <Link.FooterLink to={Link.CAREER}>
          <T>Kariéra</T>
        </Link.FooterLink>
        <Gap gap="10px" />
      </>
    )}
    <PageLinkComponent settingsKey="gdprPage" Component={Link.FooterLink}>
      <T>GDPR</T>
    </PageLinkComponent>
    <Gap gap="10px" />
    {/* <PageLinkComponent settingsKey="apartmentsPage" Component={Link.FooterLink}>
            <T>
              Apartmany
            </T>
          </PageLinkComponent> */}
    {props.intl.language === 'cs' && (
      <Link.FooterLink to={Link.FOUNDATION}>
        <T>Nadace</T>
      </Link.FooterLink>
    )}
    {props.intl.language !== 'cs' && (
      <Link.FooterLink to={Link.BLOG}>
        <T>Blog</T>
      </Link.FooterLink>
    )}
    <Gap gap="10px" />
    <PageLinkComponent settingsKey="coronaPage" Component={Link.FooterLink}>
      <T>Koronavirus</T>
    </PageLinkComponent>
    <Gap gap="10px" />
    <PageLinkComponent settingsKey="stornoConditions" isFile Component={Link.FooterLink} asA>
      <T>Storno podmínky</T>
    </PageLinkComponent>
  </>
)

const FormBlock = () => (
  <CallMeForm thanks={<ThanksMessage />} isFooter>
    <Row>
      <Col>
        <FooterTitle>
          <T>Zavoláme vám</T>
        </FooterTitle>
        <TitleGap />
        <FooterText>
          <T>Zadejte telefonní číslo v mezinárodním formátu a my se vám ozveme.</T>
        </FooterText>
        <Gap gap="16px" />
      </Col>
    </Row>
  </CallMeForm>
)

const FooterContent = props => (
  <>
    <FooterCol>
      <FooterTitle>
        <T>FORMÉ clinic</T>
      </FooterTitle>
      <TitleGap />
      <ContactItem.Address isFooter />
      <Gap gap="24px" />
      <ContactItem.Hours isFooter />
      <Gap gap="34px" />
    </FooterCol>
    <FooterCol>
      <FooterTitle>
        <T>Kontakty</T>
      </FooterTitle>
      <TitleGap />
      <ContactItem.Phone isFooter language={props?.intl?.language} />
      <Gap gap="24px" />
      <ContactItem.Email isFooter />
      <Gap gap="34px" />
    </FooterCol>
    <FooterCol maxWidth="312px">
      <FormBlock />
      <Gap gap="34px" />
    </FooterCol>
    <FooterCol shrink={2}>
      <LinksBlock {...props} />
      <Gap gap="32px" />
      <SocialsBlock />
    </FooterCol>
  </>
)

const CzFooterContent = props => (
  <>
    <FooterCol>
      <FooterTitle>
        <T>FORMÉ clinic a kontakt</T>
      </FooterTitle>
      <TitleGap />
      <ContactItem.Phone isFooter language={props?.intl?.language} />
      <Gap gap="18px" />
      <ContactItem.Email isFooter />
      <Gap gap="18px" />
      <ContactItem.Address isFooter />
      <Gap gap="18px" />
      <ContactItem.Hours isFooter />
      <Gap gap="34px" />
    </FooterCol>
    <FooterCol>
      <FormBlock />
      <Gap gap="32px" />
      <SocialsBlock />
      <Gap gap="34px" />
    </FooterCol>
    <FooterCol maxWidth="350px">
      <PayLaterBlock />
      <Gap gap="34px" />
      <Col>
        <Col maxWidth="200px">
          <FooterTitle>
            <T>Akceptujeme tyto benefitní karty</T>
          </FooterTitle>
        </Col>
        <TitleGap />
        <Row>
          <BenefitCards>
            <img src={benefitCardImage} alt="benefitní karty" />
          </BenefitCards>
        </Row>
        <Gap gap="40px" />
      </Col>
    </FooterCol>
    <FooterCol shrink={2}>
      <LinksBlock {...props} />
    </FooterCol>
  </>
)

const Footer = props => {
  const ContentComponent = props?.intl?.language === 'cs' ? CzFooterContent : FooterContent
  return (
    <Wrapper>
      <ContentWrapper>
        <FooterRow responsive>
          <ContentComponent {...props} />
        </FooterRow>
        <Gap gap="50px" />
        <Row justifyContent="center">
          <FooterText>
            2013 -{moment().format('YYYY')} © Formé clinic. <T>Všechna práva vyhrazena.</T>
          </FooterText>
        </Row>
      </ContentWrapper>
      <Gap gap="0" mobileGap="80px" />
    </Wrapper>
  )
}

Footer.defaultProps = {}

Footer.propTypes = {}

export default injectIntl(Footer)
