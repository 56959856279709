// import MuliLight from '../data/fonts/Muli-Light.ttf'
import MuliRegular from "../data/fonts/Muli-Regular.ttf"
import MuliRegularItalic from "../data/fonts/Muli-RegularItalic.ttf"
import MuliSemiBold from "../data/fonts/Muli-SemiBold.ttf"
import MuliExtraBold from "../data/fonts/Muli-ExtraBold.ttf"
import SignikaSemiBold from "../data/fonts/Signika-SemiBold.ttf"
import SignikaRegular from "../data/fonts/Signika-Regular.ttf"
import SignikaLight from "../data/fonts/Signika-Light.ttf"

export default {
  // MuliLight,
  MuliRegular,
  MuliRegularItalic,
  MuliSemiBold,
  MuliExtraBold,
  SignikaSemiBold,
  SignikaRegular,
  SignikaLight,
}
