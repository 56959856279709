import React, { Component } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { addResponsivity, up, down, between } from "../lib/styles"
// import { Col, Row, Text, Title, Gap, Link } from '.'

// custom styled components
const Iframe = styled.iframe`
  ${({ theme: { colors }, innerHeight }) => css`
    margin: 0px;
    overflow: hidden;
    width: 100% !important;
    /* outline: 1px solid ${colors.iframeBorder}; */
    margin: 0px;
    overflow: hidden;
    width: 100%;
    visibility: visible;
    opacity: 1;
    height: ${innerHeight}px;
  `}
`
class EstheticonIframe extends Component {
  state = {
    height: 100,
    wid: Math.ceil(Math.random() * 10000),
  }

  onIframeLoad = () => {
    window.addEventListener("message", this.handleIframeResize, false)
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.handleIframeResize)
  }

  handleIframeResize = event => {
    const { wid } = this.state
    // console.log('event', event)
    try {
      const data = JSON.parse(event.data)
      if (data.height && parseInt(data.wid, 10) === wid) {
        this.setState({ height: data.height })
      }
    } catch (e) {
      // console.log('error parsing message data', e)
    }
  }

  render() {
    const { eid, language } = this.props
    const { wid, height } = this.state

    const portalVersion = language === "cs" ? 1 : language === "en" ? 2 : 3

    return (
      <Iframe
        frameborder="0"
        frameBorder="0"
        scrolling="no"
        innerHeight={height}
        onLoad={this.onIframeLoad}
        src={`https://www.estheticon.com/forms/custom-widget/page/1/branch_mr?content=review_and_recommendations&wid=${wid}&client=${eid}&portal-version=${portalVersion}&version=2&abranch=1&type=large&count=10&skin=light`}
      />
    )
  }
}

EstheticonIframe.propTypes = {}

export default EstheticonIframe
