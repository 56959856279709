import React from "react"
import styled, { css } from "styled-components"
import { addResponsivity, up, down, between } from "../lib/styles"
import chevronPath from "../data/images/icon-chevron-down.svg"
import iconCaretPath from "../data/images/icon-menu-caret.svg"

const Wrapper = styled.div`
  padding: 10px;
`

const ChevronImage = styled.img`
  ${({
    isOpen,
    width = "19px",
    height = "10px",
    mobileWidth,
    mobileHeight,
    bp = "mobile",
  }) => css`
    ${addResponsivity()}
    width: ${mobileWidth || width};
    height: ${mobileHeight || height};
    transition: 0.3s;
    ${isOpen &&
    css`
      transform: rotate(180deg);
    `}

    ${up(bp)} {
      width: ${width};
      height: ${height};
    }
  `}
`
const CaretImage = styled.img`
  ${({
    isOpen,
    width = "12px",
    height = "6px",
    mobileWidth,
    mobileHeight,
    bp = "mobile",
  }) => css`
    ${addResponsivity()}
    width: ${mobileWidth || width};
    height: ${mobileHeight || height};
    transition: 0.3s;
    ${isOpen &&
    css`
      transform: rotate(180deg);
    `}

    ${up(bp)} {
      width: ${width};
      height: ${height};
    }
  `}
`

// basic is used on procedures page
const ChevronIcon = props => <ChevronImage {...props} src={chevronPath} />

ChevronIcon.FAQ = props => (
  <ChevronIcon
    {...props}
    width="18px"
    height="9px"
    mobileWidth="19px"
    mobileHeight="10px"
  />
)

ChevronIcon.Select = props => (
  <Wrapper>
    <CaretImage src={iconCaretPath} isOpen={props.selectProps.menuIsOpen} />
  </Wrapper>
)

const CareerChevron = styled(ChevronIcon)`
  transform: rotate(-90deg);
`

ChevronIcon.Career = props => (
  <CareerChevron
    {...props}
    width="18px"
    height="9px"
    mobileWidth="19px"
    mobileHeight="10px"
  />
)

export default ChevronIcon
