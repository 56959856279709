import React from "react"
import styled, { css } from "styled-components"
import Text from "./Text"
import Col from "./Col"
import Row from "./Row"
import Title from "./Title"
import Gap from "./Gap"
import ContactItem from "./ContactItem"
import Button from "./Button"
import Link from "./Link"
import VH from "./VH"
import { up, theme } from "../lib/styles"
import T from "../lib/intl/T"

import addressIconWhitePath from "../data/images/contact/address-icon-white.svg"

import safetyIcon1Path from "../data/images/covid-bar/ruce.svg"
import safetyIcon2Path from "../data/images/covid-bar/rousky.svg"
import safetyIcon3Path from "../data/images/covid-bar/rozestupy.svg"

const BP = theme.covidBar.bp1
const BP_2 = theme.covidBar.bp2
const BP_3 = theme.covidBar.bp3

const CovidText = props => (
  <Text
    fontSize="12px"
    lineHeight="14px"
    mobileFontSize="12px"
    mobileLineHeight="14px"
    fontWeight="600"
    white
    {...props}
  />
)

const CovidButton = styled(Button).attrs({
  padding: "0 16px",
  height: "32px",
})`
  ${({ theme: { colors } }) => css`
    color: ${colors.green};
    background: white;
    min-width: 138px;
    &:hover {
      background-color: #d6fbf9;
    }
  `}
`

// const CovidButton = props => <Button.link responsiveFullWidth={false}{...props}/>

const Wrapper = styled.div`
  ${({
    theme: {
      sizes: { maxPageWidth },
      colors,
    },
  }) => css`
    max-width: ${maxPageWidth}px;
    width: 100%;
    background: ${colors.green};
    z-index: 1;
    min-height: 88px;
    display: flex;
    flex-direction: column;
    position: relative;
    margin: 0 auto;
    border-radius: 0 0 16px 16px;
    padding: 20px 10px 20px 10px;
    align-items: center;
    justify-content: center;
  `}
`

const Container = styled.div`
  ${({ theme }) => css`
    max-width: 1280px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    ${up(BP)} {
      flex-direction: column;
    }
  `}
`

const CovidBar = () => (
  <Wrapper>
    <Container>
      <Row
        responsive
        bp={BP}
        justifyContent="space-between"
        alignItems="center"
        maxWidth="1130px"
        width="100%"
      >
        <Row alignItems="center" responsive bp={BP_3}>
          <Col mobileAlignItems="center" alignItems="flex-start" bp={BP}>
            <CovidText upperCase>
              <T>Aktuální informace</T>
            </CovidText>
            <Gap gap="4px" />
            <Title.SmallerTitle white>
              <T>Máme pro vás otevřeno!</T>
            </Title.SmallerTitle>
          </Col>
          {/* <Gap gap="40px" mobileGap="20px" bp={BP_3} />
          <Col>
            <ContactItem.Address isCovidBar isFooter />
          </Col> */}
        </Row>
        <Gap gap="40px" mobileGap="0" bp={BP} />
        <VH showAfter={BP}>
          <Row alignItems="center" responsive bp={BP_2}>
            <Row responsive bp={BP_3} alignItems="center">
              <Col>
                <Text white>
                  <T>Vaše bezpečnost je naše priorita.</T>
                </Text>
                <Text white>
                  <T>Dodržujeme pravidlo 3R.</T>
                </Text>
              </Col>
              <Gap gap="34px" mobileGap="20px" bp={BP_3} />
              <Row>
                <img src={safetyIcon1Path} alt="ruce" />
                <Gap gap="24px" />
                <img src={safetyIcon2Path} alt="roušky" />
                <Gap gap="24px" />
                <img src={safetyIcon3Path} alt="rozestupy" />
              </Row>
            </Row>
          </Row>
        </VH>
        <Gap gap="40px" mobileGap="0" bp={BP} />
        <VH showAfter={BP}>
          <Row>
            <Link.PageLinkComponent settingsKey="coronaPage" Component={Link}>
              <CovidButton height="32px">
                <T>Covid-19 info</T>
              </CovidButton>
            </Link.PageLinkComponent>
          </Row>
        </VH>
      </Row>
      <Gap gap="16px" />
      <Row maxWidth="100%" mobileMaxWidth="650px" bp={1230}>
        <Text white textAlign="center" mobileTextAlign="center" bp={1230}>
          <T raw>
            {
              "Formé clinic je zdravotnické zařízení. Návštěva kliniky a veškeré zákroky jsou <b>v souladu s aktuálními vládními opatřeními COVID-19 platnými v České republice.</b>"
            }
          </T>
        </Text>
      </Row>
      <Gap gap="0" mobileGap="20px" bp={BP} />
      <VH showBelow={BP}>
        <Row>
          <Link.PageLinkComponent settingsKey="coronaPage" Component={Link}>
            <CovidButton height="32px">
              <T>Covid-19 info</T>
            </CovidButton>
          </Link.PageLinkComponent>
        </Row>
      </VH>
    </Container>
  </Wrapper>
)

export default CovidBar
