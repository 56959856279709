import React from "react"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"

const YoutubeIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="33"
    viewBox="0 0 32 33"
  >
    <g>
      <g>
        <g>
          <g>
            <path fill="#fff" d="M13.87 20.88l6.4-3.745-6.4-3.746z" />
          </g>
          <g>
            <path
              fill="#fff"
              d="M25.588 20.793s-.192 1.446-.778 2.082c-.745.835-1.58.84-1.962.889-2.74.21-6.853.218-6.853.218s-5.09-.05-6.656-.211c-.436-.087-1.413-.062-2.159-.897-.587-.636-.778-2.081-.778-2.081s-.195-1.697-.195-3.396v-1.59c0-1.698.195-3.395.195-3.395s.192-1.447.778-2.083c.746-.835 1.58-.84 1.962-.887 2.739-.214 6.848-.214 6.848-.214H16s4.109 0 6.848.214c.383.047 1.217.052 1.962.887.587.636.778 2.083.778 2.083s.195 1.697.195 3.395v1.591c0 1.698-.195 3.395-.195 3.395zM16 .547C7.163.547 0 7.734 0 16.6s7.163 16.053 16 16.053S32 25.466 32 16.6 24.837.547 16 .547z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

YoutubeIcon.defaultProps = {}

YoutubeIcon.propTypes = {}

export default YoutubeIcon
