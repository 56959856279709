import React from 'react'
import styled, { css } from 'styled-components'

import { Menu, Footer, StickyForm, WhatsAppButton } from '../components'
import CookiesFooter from '../components/CookiesFooter'
import { up } from '../lib/styles'
import App from './App'
import AllPopups from '../components/AllPopups'
import CovidBar from '../components/CovidBar'
import { ModalRoot } from '../components/Modals'

const PageWrapper = styled.div`
  position: relative;
  z-index: 1;
  overflow-x: hidden;
`

const Page = styled.div`
  ${({ theme: { sizes } }) => css`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    /* global page padding here! */
    padding: 0 20px;
    ${up('mobile')} {
      padding: 0 24px;
    }
  `}
`

const PageContent = styled.div`
  ${({ theme: { sizes } }) => css`
    width: 100%;
    max-width: ${sizes.pageWidth}px;
    display: flex;
    flex-direction: column;
    align-content: stretch;
  `}
`

const FixedWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  pointer-events: none;
`

class DefaultLayout extends React.Component {
  componentDidMount() {
    const { language } = this.props.pageContext.intl

    if (!window.dataLayer) {
      window.dataLayer = []
    }
    window.dataLayer.push({
      language,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      pageContext: { intl },
    } = prevProps
    const prevLang = intl && intl.language

    const { language } = this.props.pageContext.intl

    if (language !== prevLang) {
      window.dataLayer.push({
        language,
      })
    }
  }

  render() {
    // these props are exactly same as pageProps injected by gatsby
    // console.log('this.props LAYOUT', this.props)
    const {
      location: { pathname },
      pageContext: { intl, trimmedPath },
    } = this.props

    return (
      <>
        <App intl={intl} trimmedPath={trimmedPath}>
          <AllPopups />
          <ModalRoot />
          <PageWrapper>
            {/* <CovidBar/> */}
            <Menu pathname={pathname} />
            <Page>
              <PageContent>{this.props.children}</PageContent>
            </Page>
            <Footer />
          </PageWrapper>
          <FixedWrapper>
            <StickyForm style={{ pointerEvents: 'auto' }} />
            <CookiesFooter style={{ pointerEvents: 'auto' }} />
            <WhatsAppButton />
          </FixedWrapper>
        </App>

        <div id="notifee-dialog-wrapper" />
        <div id="notifee-icon-wrapper"/>
        <div id="notifee-banner-wrapper"/>
      </>
    )
  }
}

export default DefaultLayout
