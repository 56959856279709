import React from "react"
import styled, { css } from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import Row from "./Row"
import Text from "./Text"
import Title from "./Title"
import Gap from "./Gap"
import Col from "./Col"
import { up } from "../lib/styles"
import T from "../lib/intl/T"
import {
  PHONE_NUMBER,
  PHONE_NUMBER_EN,
  PHONE_NUMBER_EN_PREFIX,
} from "../lib/config"

import addressIconWhitePath from "../data/images/contact/address-icon-white.svg"
import addressIconPath from "../data/images/contact/address-icon.svg"
import phoneIconPath from "../data/images/contact/phone-icon.svg"
import phoneEmergencyIconPath from "../data/images/contact/phone-emergency-icon.svg"
import hoursIconPath from "../data/images/contact/hours-icon.svg"
import parkingIconPath from "../data/images/contact/parking-icon.svg"

const IconWrapper = styled.div`
  ${({ theme, topAlign }) => css`
    margin-right: 16px;
    margin-top: ${topAlign ? "8px" : 0};
  `}
`

const ContactText = styled(Text)`
  ${({ theme: { colors, fonts }, isFooter }) => css`
    font-family: ${fonts.signika};
    font-weight: 600;
    color: ${isFooter ? colors.white : colors.blue};

    ${!isFooter &&
    css`
      font-size: 22px;
      line-height: 32px;
      ${up("mobile")} {
        font-size: 20px;
        line-height: 30px;
      }
    `}

    ${isFooter &&
    css`
      font-weight: 600;
      line-height: 24px;
      font-size: 16px;
      font-family: ${fonts.muli};
    `}

    span {
      font-weight: ${isFooter ? "inherit" : 400};
    }
  `}
`

const MailTo = styled.a`
  ${({ theme: { colors, fonts }, isFooter, small }) => css`
    font-family: ${fonts.signika};
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    text-decoration: none;
    color: ${isFooter ? colors.white : colors.green};

    ${isFooter &&
    css`
      font-weight: 600;
      line-height: 24px;
      font-size: 16px;
      font-family: ${fonts.muli};
      &:hover {
        color: ${colors.white};
      }
    `}
    ${small &&
    css`
      font-weight: 400;
      line-height: 24px;
      font-size: 16px;
    `}
  `}
`

const ContactItem = ({ src, children, isFooter, topAlign, ...props }) => (
  <Row alignItems={topAlign ? "flex-start" : "center"} {...props}>
    <IconWrapper topAlign={topAlign}>
      <img src={src} alt="" />
    </IconWrapper>
    <ContactText isFooter={isFooter} fontWeight={600} lineHeight="30px">
      {children}
    </ContactText>
  </Row>
)

const MapLink = props => (
  <a
    target="_blank"
    href="https://www.google.com/maps/place/Form%C3%A9+Clinic+-+plastick%C3%A1+a+estetick%C3%A1+chirurgie/@50.0861076,14.4566981,17z/data=!3m1!4b1!4m5!3m4!1s0x470b94ed3ba531fd:0x5b495d0ee1a78901!8m2!3d50.0861076!4d14.4588867?hl=cs"
    {...props}
  />
)

ContactItem.MapLink = MapLink

ContactItem.Address = ({ isCovidBar, ...props }) => (
  <MapLink>
    <ContactItem
      src={isCovidBar ? addressIconWhitePath : addressIconPath}
      {...props}
    >
      Jeseniova 30
      <br />
      Praha 3, 130&nbsp;00
    </ContactItem>
  </MapLink>
)

const withSettings = Component => props => {
  const { settings } = useStaticQuery(graphql`
    query OrthopedicsContactQuery {
      settings {
        orthopedicsContactName
        orthopedicsContactPhone
        orthopedicsContactEmail
        openingHours
      }
    }
  `)
  return <Component {...props} settings={settings} />
}

// opening hours

ContactItem.Hours = withSettings(({ settings, ...props }) => (
  <ContactItem src={hoursIconPath} {...props}>
    <span>
      <T raw>Otevřeno</T>{" "}
    </span>
    {settings?.openingHours || "7:00 - 18:00"}
  </ContactItem>
))

ContactItem.Email = ({ isFooter, ...props }) => (
  <ContactItem
    src="/static-images/mail-icon.svg"
    {...props}
    topAlign={!isFooter}
  >
    {!isFooter && <Gap />}
    <MailTo isFooter={isFooter} href="mailto:recepce@formeclinic.cz">
      recepce@formeclinic.cz
    </MailTo>
    {/* {!isFooter && (
      <>
        <Gap />
        <Title.SmallestTitle>
          <T>Kontakt na apartmány</T>
        </Title.SmallestTitle>
        <Text.Small>
          <T raw>{'Pro rezervaci apartmánu <br />nás kontaktujte na:'}</T>
          <br />
          <MailTo small href="mailto:info@formeapartments.cz">
            info@formeapartments.cz
          </MailTo>
        </Text.Small>
      </>
    )} */}
  </ContactItem>
)

ContactItem.Phone = ({ language, ...props }) => (
  <ContactItem src={phoneIconPath} {...props}>
    {/* {language === "en" && (
      <>
        <a href={`tel:${PHONE_NUMBER_EN_PREFIX}${PHONE_NUMBER_EN}`}>
          <span>{PHONE_NUMBER_EN_PREFIX}</span> {PHONE_NUMBER_EN}
        </a>
        <br />
      </>
    )} */}
    <a href={`tel:+420${PHONE_NUMBER}`}>
      <span>+420</span> {PHONE_NUMBER}
    </a>
    <br />
    <a href="tel:+420776553244">
      <span>+420</span> 776 553 244
    </a>
  </ContactItem>
)

ContactItem.EmergencyPhone = ({ language, ...props }) => (
  <ContactItem src={phoneEmergencyIconPath} {...props}>
    <Title.SmallestTitle>
      <T>Zdravotní pohotovost</T>
    </Title.SmallestTitle>
    <a href="tel:+420775433077">
      <span>+420</span> 775 433 077
    </a>
  </ContactItem>
)

ContactItem.Parking = props => (
  <ContactItem src={parkingIconPath} maxWidth="341px" topAlign {...props}>
    <Title.SmallestTitle>
      <T>Jak se k nám dostanete?</T>
    </Title.SmallestTitle>
    <Text.Small>
      <T>
        Najdete nás 10 minut tramvají od Hlavního nádraží v Praze. Z tramvajové
        zastávky Hlavní nádraží k nám jedou tramvaje 5, 9, 15 a 26 na zastávku
        Olšanské náměstí a odtud je to 5 minut směr park Parukářka. Autem můžete
        parkovat u nás v garážích.
      </T>
    </Text.Small>
  </ContactItem>
)

ContactItem.Orthopedics = withSettings(({ settings, ...props }) => {
  // todo odstranit defaultní hodnoty, pokud budou v keystone na produkci
  const name = settings.orthopedicsContactName || "Zuzana Winterová"
  const email =
    settings.orthopedicsContactEmail || "zuzana.winterova@formeclinic.cz"
  const phone = settings.orthopedicsContactPhone || "777 343 020"
  const phoneWithoutWhitespace = phone.replace(/\s/g, "")

  return (
    <Col {...props}>
      <ContactText>
        <Title.SmallestTitle>{name}</Title.SmallestTitle>
        <Text.Small>
          <T>Klient koordinátor pro Ortopedii</T>
        </Text.Small>
        <Gap gap="8px" />
        <a href={`tel:+420${phoneWithoutWhitespace}`}>
          <span>+420</span> {phone}
        </a>
        <br />
        <MailTo small href={`mailto:${email}`}>
          {email}
        </MailTo>
      </ContactText>
    </Col>
  )
})

export default ContactItem
