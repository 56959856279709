import React from "react"
import styled, { css } from "styled-components"
import { Text } from "."
import { up, addResponsivity } from "../lib/styles"

// default values belong to Page title
const Title = styled.span`
  ${({
    theme: { colors },
    fontSize = "56px",
    fontWeight = 700,
    lineHeight = "64px",
    mobileFontSize,
    mobileLineHeight,
    gray,
    white,
    italicGray,
    maxWidth,
    mobileMaxWidth,
    textAlign,
    alignSelf,
    mobileTextAlign,
    display = "block",
    bp = "mobile", // can be px or bp name
    margin,
  }) => css`
    font-weight: ${fontWeight};
    color: ${gray ? colors.lightGray : colors.blue};
    color: ${italicGray && colors.italicGray};
    color: ${white && "white"};
    font-family: "Signika", serif;
    max-width: ${mobileMaxWidth || maxWidth};
    align-self: ${alignSelf};
    display: ${display};

    font-size: ${mobileFontSize || fontSize};
    line-height: ${mobileLineHeight || lineHeight};
    text-align: ${mobileTextAlign || textAlign};

    /* reset */
    margin: 0;
    margin: ${margin};
    padding: 0;

    ${up(bp)} {
      font-size: ${fontSize};
      line-height: ${lineHeight};
      text-align: ${textAlign};
      max-width: ${maxWidth};
    }

    ${addResponsivity({ displayAs: display })}
  `}
`

// define basic styles used on page
// PROTIP: preddefinovane mobile / desktop velikosti, lze ovlivnit nastavenim vlastniho BP

// all pages h1 title (except homepage)
Title.PageTitle = props => (
  <Title
    mobileFontSize="40px"
    mobileLineHeight="48px"
    as={props.innerAs || "h1"}
    {...props}
  />
)

// usually h2 title, bigger version
// eg. hp sections, blog title...
// !! auto-responsive!
Title.BiggerTitle = props => (
  <Title
    fontSize="48px"
    lineHeight="56px"
    mobileFontSize="32px"
    mobileLineHeight="40px"
    as={props.innerAs || "h2"}
    {...props}
  />
)

// usually h2 title, big version (slightly smaller bigger title :D )
// !! auto-responsive!
Title.BigTitle = props => (
  <Title
    fontSize="40px"
    lineHeight="48px"
    mobileFontSize="32px"
    mobileLineHeight="40px"
    as={props.innerAs || "h2"}
    {...props}
  />
)

// nazvy promen, paticka dotaz (neresponzivni - nutno pouzit props)
Title.MediumTitle = props => (
  <Title
    fontSize="32px"
    lineHeight="40px"
    mobileFontSize={props.responsive ? "28px" : undefined}
    mobileLineHeight={props.responsive ? "36px" : undefined}
    as={props.innerAs || "h3"}
    {...props}
  />
)

Title.SmallTitle = props => (
  <Title
    fontSize="28px"
    lineHeight="36px"
    as={props.innerAs || "h3"}
    {...props}
  />
)

// nazvy zakroku, cnea zakroku
Title.SmallerTitle = props => (
  <Title
    fontSize="24px"
    lineHeight="30px"
    // mobileFontSize="24px" --> stejne
    // mobileLineHeight="30px"
    as={props.innerAs || "h3"}
    {...props}
  />
)

Title.SmallGrayTitle = props => (
  <Title
    fontSize="22px"
    lineHeight="30px"
    // mobileFontSize="24px" --> stejne
    // mobileLineHeight="30px"
    fontWeight={400}
    italicGray
    as={props.innerAs || "h4"}
    {...props}
  />
)

Title.SmallestTitle = props => (
  <Title
    fontSize="20px"
    lineHeight="30px"
    as={props.innerAs || "h4"}
    {...props}
  />
)

Title.TinyTitle = props => (
  <Title
    fontSize="18px"
    lineHeight="26px"
    fontWeight={600}
    as={props.innerAs || "h4"}
    {...props}
  />
)

export default Title
