import React, { Fragment } from "react"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"
import Slider from "react-slick"
import HexaSection from "./HexaSection"
import Title from "./Title"
import Text from "./Text"
import Gap from "./Gap"
import Link from "./Link"
import Button from "./Button"
import { addPagePadding, up } from "../lib/styles"
import T from "../lib/intl/T"

import quotationStartPath from "../data/images/quotation-start.svg"
import quotationEndPath from "../data/images/quotation-end.svg"

import arrowRightPath from "../data/images/arrow-right.svg"
import arrowLeftPath from "../data/images/arrow-left.svg"

const sliderSettings = {
  dots: false,
  infinite: true,
  arrows: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
}

const SliderWrapper = styled.div`
  position: relative;
  max-width: 665px;
  margin: auto;
`

const ContentWrapper = styled.div`
  position: relative;
  max-width: 470px;
  margin: auto;
`

const Wrapper = styled.div`
  ${addPagePadding()}
`

const quotationBreakpoint = 700

const QuotationWrapper = styled.div`
  width: 45px;
  position: relative;
  ${up(quotationBreakpoint)} {
    width: 56px;
    position: absolute;
  }
  img {
    /* override slick style */
    display: inline-block !important;
  }
`

const QuotationStartWrapper = styled(QuotationWrapper)`
  margin-bottom: 16px;
  ${up(quotationBreakpoint)} {
    left: -88px;
  }
`

const QuotationEndWrapper = styled(QuotationWrapper)`
  text-align: right;
  width: 100%;
  margin-top: 16px;

  ${up(quotationBreakpoint)} {
    right: -88px;
    transform: translateY(-39px);
  }
`

const ArrowWrapper = styled.div`
  position: absolute;
  top: 35%;
  transform: translateY(-50%);
  padding: 100px 10px;
  cursor: pointer;
`

const arrowPosition = "-20%"

const ArrowWrapperLeft = styled(ArrowWrapper)`
  left: ${arrowPosition};
`
const ArrowWrapperRight = styled(ArrowWrapper)`
  right: ${arrowPosition};
`
class TestimonialsSection extends React.Component {
  slick = null

  next = () => this.slick && this.slick.slickNext()

  prev = () => this.slick && this.slick.slickPrev()

  render() {
    const { data, showMoreReferencesLink } = this.props
    return (
      <HexaSection>
        <Wrapper>
          <ContentWrapper>
            <Title.BiggerTitle>
              <T>Co říkají naši klienti</T>
            </Title.BiggerTitle>
            <Gap gap="30px" />
          </ContentWrapper>

          <SliderWrapper>
            {data.length > 1 && (
              <>
                <ArrowWrapperLeft onClick={this.prev}>
                  <img src={arrowLeftPath} alt="" />
                </ArrowWrapperLeft>
                <ArrowWrapperRight onClick={this.next}>
                  <img src={arrowRightPath} alt="" />
                </ArrowWrapperRight>
              </>
            )}

            <Slider
              {...sliderSettings}
              ref={ref => {
                this.slick = ref
              }}
            >
              {data.map(({ info, content, name }, key) => (
                <Fragment key={`${key}${name}`}>
                  <ContentWrapper>
                    <QuotationStartWrapper>
                      <img src={quotationStartPath} alt="" />
                    </QuotationStartWrapper>

                    <Text.Intro>{content}</Text.Intro>

                    <QuotationEndWrapper>
                      <img src={quotationEndPath} alt="" />
                    </QuotationEndWrapper>
                  </ContentWrapper>

                  <ContentWrapper>
                    <Gap gap="24px" />
                    <Title fontSize="22px" lineHeight="30px">
                      {name}
                    </Title>
                    <Gap gap="4px" />
                    <Text.Italic>{info}</Text.Italic>
                  </ContentWrapper>
                </Fragment>
              ))}
            </Slider>
          </SliderWrapper>

          {showMoreReferencesLink && (
            <ContentWrapper>
              <Gap gap="30px" />
              <Button.Link to={Link.REFERENCES}>
                <T>více recenzí</T>
              </Button.Link>
            </ContentWrapper>
          )}
        </Wrapper>
      </HexaSection>
    )
  }
}

TestimonialsSection.defaultProps = {
  data: [],
  showMoreReferencesLink: true,
}

TestimonialsSection.propTypes = {
  data: PropTypes.array,
  showMoreReferencesLink: PropTypes.bool,
}

export default TestimonialsSection
