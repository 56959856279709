import React from "react"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"

const FacebookIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="33"
    viewBox="0 0 32 33"
  >
    <g>
      <g>
        <path
          fill="#fff"
          d="M20.244 16.534H17.47v9.914h-4.11v-9.914h-1.956v-3.5h1.956v-2.269c0-1.623.768-4.157 4.149-4.157l3.044.011v3.4h-2.21c-.361 0-.872.18-.872.953v2.062h3.134zM16 .547C7.163.547 0 7.736 0 16.6c0 8.866 7.163 16.052 16 16.052s16-7.186 16-16.052C32 7.736 24.836.547 16 .547z"
        />
      </g>
    </g>
  </svg>
)

FacebookIcon.defaultProps = {}

FacebookIcon.propTypes = {}

export default FacebookIcon
