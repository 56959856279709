import React from "react"
import styled, { css } from "styled-components"
import { up, addResponsivity } from "../lib/styles"

const Col = styled.div`
  ${({
    theme: { colors },
    justifyContent,
    alignItems,
    alignSelf,
    mobileAlignSelf,
    mobileAlignItems,
    position,
    maxWidth,
    mobileMaxWidth,
    minWidth,
    width,
    mobileWidth,
    flex,
    grow,
    basis,
    shrink,
    padding,
    margin,
    mobilePadding,
    mobileMargin,
    bp = "mobile",
  }) => css`
    display: flex;
    flex-direction: column;
    align-items: ${mobileAlignItems || alignItems};
    justify-content: ${justifyContent};
    max-width: ${mobileMaxWidth || maxWidth};
    min-width: ${minWidth};
    width: ${mobileWidth || width};
    position: ${position};
    flex: ${flex};
    align-self: ${mobileAlignSelf || alignSelf};
    flex-grow: ${grow};
    flex-basis: ${basis};
    flex-shrink: ${shrink};
    padding: ${mobilePadding || padding};
    margin: ${mobileMargin || margin};

    ${up(bp)} {
      padding: ${padding};
      margin: ${margin};
      align-self: ${alignSelf};
      max-width: ${maxWidth};
      align-items: ${alignItems};
      width: ${width};
    }

    ${addResponsivity({ displayAs: "flex" })}
  `}
`

export default Col
