import React from "react"
import styled, { css } from "styled-components"
import { addResponsivity, up, down, between } from "../lib/styles"

/**
 
 * eg. for youtube player
 * correct english term is round not rounded https://wikidiff.com/rounded/round
 */

const RoundWrapper = styled.div`
  ${addResponsivity()}

  ${({ big, small }) => css`
    border-radius: ${big ? 16 : small ? 8 : 12}px;
    overflow: hidden;
  `}
`

export default RoundWrapper
