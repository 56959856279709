import React, { Component } from "react"
import axios from "axios"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"
import Input from "./Input"
import Row from "./Row"
import Col from "./Col"
import Button from "./Button"
import Title from "./Title"
import Text from "./Text"
import Gap from "./Gap"
import WaveImage from "./WaveImage"
import VH from "./VH"
import { up } from "../lib/styles"
import T from "../lib/intl/T"
import { urlBase } from "../lib/config"
import injectIntl from "../lib/intl/injectIntl"

import borderImage from "../data/images/side-button-border.png"
import { trackLead } from "../lib/fb"

const phoneInputMixin = () => css`
  max-width: 170px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`

const PhoneInput = styled(Input)`
  ${({ theme: { colors, breakpoints }, isFooter, isSticky }) => css`
    width: 100%;
    min-width: 0;

    ${isFooter &&
    css`
      border: none;
      margin-right: 0;
      ${phoneInputMixin()}
    `}

    ${isSticky &&
    css`
      border: none;
      margin-right: 0;
      ${phoneInputMixin()}
      &:focus {
        border-color: white;
      }
    `}

    ${!isFooter &&
    !isSticky &&
    css`
      height: 48px;

      ${up(breakpoints.mobile)} {
        height: 40px;
        ${phoneInputMixin()}
      }
    `}
  `}
`

const SideButton = styled.div`
  ${({ theme: { colors, fonts }, isFooter }) => css`
    position: relative;
    border-radius: 0 12px 12px 0;
    height: 40px;
    line-height: 40px;
    background-color: ${colors.white};
    color: ${colors.green};
    cursor: pointer;

    color: ${colors.green};
    font-family: ${fonts.muli};
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    padding: 0 16px;
    transition: 0.2s;

    border-left: solid 1px ${colors.green};

    ${!isFooter &&
    css`
      /* border-image: url(${borderImage});
        border-image-width: 3px;
        border-image-slice: 4;
        border-image-repeat: round; */
      border: 2px dashed rgba(0, 179, 166, 0.75);
      border-left: none;
      margin-left: 4px;
      line-height: 38px;
    `}

    &:hover {
      background-color: #d6fbf9;
    }
  `}
`

const Wrapper = styled(Row)`
  max-width: none;
  ${up("mobile")} {
    max-width: 312px;
  }
`

const ConfirmButton = (props = {}) => {
  if (props.isFooter) return <SideButton {...props} data-cta="callme" />
  return (
    <>
      <VH showAfter="mobile">
        <SideButton {...props} data-cta="callme" />
      </VH>
      <VH showBelow="mobile">
        <Col>
          <Gap gap="8px" />
          <Button.Dashed {...props} data-cta="callme" />
        </Col>
      </VH>
    </>
  )
}

class CallMeForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      number: "",
      isSent: false,
    }
  }

  onConfirm = async () => {
    const {
      intl: { t },
    } = this.props
    const { number } = this.state
    if (!number) return
    this.setState({ isSent: true })

    try {
      await axios.post("/api/v1/inquiries/call-me", {
        phoneNumber: number.trim(),
        locationUrl: window.location.href,
      })
      this.setState({ number: "" })
      trackLead()
    } catch (error) {
      console.log('error', error)
      this.setState({ isSent: false })
      setTimeout(
        // call after ui update
        () =>
          alert(
            t(
              "Došlo k chybě při odesílání formuláře, zkuste to prosím později."
            )
          ),
        1
      )
    }
  }

  render() {
    const { children, thanks, isFooter, isSticky } = this.props
    const { number, isSent } = this.state
    if (isSent) return thanks
    return (
      <Wrapper>
        <Col>
          {children}
          <Row responsive={!isFooter && !isSticky} wrap="nowrap">
            <Col shrink={1}>
              <PhoneInput
                placeholder="+420"
                value={number}
                onChange={({ target }) =>
                  this.setState({ number: target.value })
                }
                isFooter={isFooter}
                isSticky={isSticky}
              />
            </Col>
            <Col shrink={0}>
              <ConfirmButton
                isFooter={isFooter || isSticky}
                onClick={this.onConfirm}
              >
                <T>zavolejte mi</T>
              </ConfirmButton>
            </Col>
          </Row>
        </Col>
      </Wrapper>
    )
  }
}

CallMeForm.defaultProps = {
  children: null,
  thanks: null,
  isFooter: false,
  isSticky: false,
}

CallMeForm.propTypes = {
  children: PropTypes.node,
  thanks: PropTypes.node,
  isFooter: PropTypes.bool,
  isSticky: PropTypes.bool,
  intl: PropTypes.object.isRequired,
}

const CallMeFormWithIntl = injectIntl(CallMeForm)

const ThanksMessage = () => (
  <Title.SmallestTitle>
    <T raw>{"Děkujeme,<br />ozveme se vám obratem."}</T>
  </Title.SmallestTitle>
)

CallMeFormWithIntl.Basic = props => (
  <CallMeFormWithIntl thanks={<ThanksMessage />}>
    <Col alignItems="flex-start">
      <WaveImage />
      <Gap gap="16px" />
      <Title.SmallestTitle>
        <T>Zavoláme vám</T>
      </Title.SmallestTitle>
      <Text.Small responsive>
        <T>
          Zadejte telefonní číslo v mezinárodním formátu a my se vám ozveme.
        </T>
      </Text.Small>
      <Gap gap="16px" />
    </Col>
  </CallMeFormWithIntl>
)

export default CallMeFormWithIntl
