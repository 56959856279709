import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { addResponsivity, up, down, between } from "../lib/styles"
import { Col, Row, Text, Title, Gap, Link } from "."

import maskPath from "../data/images/hexa-mask.svg"
import maskBottomPath from "../data/images/hexa-mask-bottom.svg"

const StyledImg = styled(GatsbyImage)`
  ${({ small }) => css`
    /* ${addResponsivity()}
    position: relative;
    width: 100%; */

    &::before {
      content: "";
      display: block;
      width: 100%;
      /* height: 260px; */
      height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      transform-origin: 0% 0%;
      transform: translateX(-50.1%) scale(1.006) translateY(-0.1%);
      ${up("mobile")} {
        transform: scale(1.008) translateX(-50%) translateY(-0.1%);
      }
      z-index: 2;
      background: url(${maskPath});
      background-repeat: no-repeat;
      background-position: top center;
      background-size: 100%;
    }
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform-origin: 50% 100%;
      transform: translateX(-50%) translateY(0.6%) scale(1.013);
      ${up("mobile")} {
        transform: translateX(-50.1%) translateY(0.5%) scale(1.005);
        ${small &&
        css`
          transform: translateX(-50%) translateY(0.5%) scale(1.017);
        `}
      }
      z-index: 1;
      background: url(${maskBottomPath});
      background-repeat: no-repeat;
      background-position: bottom center;
      background-size: 100%;
    }
  `}
`

class HexaImage extends React.Component {
  render() {
    const { ...rest } = this.props
    return (
      // <Wrapper>
      <StyledImg {...rest} />
      // </Wrapper>
    )
  }
}

HexaImage.propTypes = {}

export default HexaImage
