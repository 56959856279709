module.exports = {
  '/// adresy url stranek': '-------',
  '/dev-404-page': '/dev-404-page',
  '/404': '/404',
  '/404.html': '/404.html',
  '/': '/',
  '/about': '/o-nas',
  '/procedures': '/zakroky',
  '/blog': '/blog',
  '/// meta informace statickych stranek': '------',
  'blog - meta title': 'Blog',
  'blog - meta desc': 'Sledujte novinky a zajímavosti ze světa plastické chirurgie.',
  '404 - meta title': 'Strana nenalezena',
  'O klinice': 'O klinice',
  'O nás': 'O nás',
  'Náš tým': 'Náš tým',
  'Zákroky': 'Zákroky',
  'Před a po': 'Před a po',
  'RECENZE': 'RECENZE',
  'Blog': 'Blog',
  'Kontakt': 'Kontakt',
  'Nadace': 'Nadace',
  'procedures - meta title': 'Zákroky a operace',
  'Stránka nebyla nalezena': 'Stránka nebyla nalezena',
  'Přejít na FORMÉ clinic': 'Přejít na Formé clinic',
  'about - meta title': 'O nás',
  'about - meta desc': 'Renomovaná klinika plastické chirurgie v Praze výjimečná naším osobním přístupem. Jsme klinika pro každého s rodinnou atmosférou. Formé Clinic je specializovaná klinika plastické chirurgie s apartmány pro Vás a Vaše nejbližší.',
  'team - meta title': 'Náš tým',
  'team - meta desc': 'Náš tým se skládá ze skvělých profesionálů ve svém oboru. Mají za sebou tisíce zákroků. Naši chirurgové totiž neoperují jen v Česku, ale také jinde v Evropě nebo v USA.',
  'changes - meta title': 'Před a po - Proměny',
  'changes - meta desc': 'Podívejte se na proměny našich klientů. Najdete u nich videa, rozhovory s lékaři, fotografie před a po ošetření, dojmy a recenze klientů. Jak jsou spokojeni s výsledkem, co říkají na naše doktory a jak se jim líbily prostory Formé clinic?',
  'references - meta title': 'Recenze našich klientů',
  'references - meta desc': 'Co o nás říkají naši klienti. Recenze přímo z renovovaného a nezávislého portálu Estheticon.',
  'foundation - meta title': 'Nadace Formé',
  'foundation - meta desc': 'Myšlenka nadačního fondu vznikla v říjnu roku 2017 s cílem poskytnout všestrannou chirurgickou a poradenskou pomoc a přiblížit činnost kliniky běžným lidem. Plastická chirurgie není jen pro bohaté a pro celebrity, ale pro všechny, kteří potřebují vyřešit nějaký problém. ',
  'contact - meta title': 'Kontaktujte nás',
  'Volejte': 'Volejte',
  'další články': 'další články',
  'blog': 'blog',
  'další příběhy': 'další příběhy',
  'Proměny našich klientů': 'Proměny našich klientů',
  'Podívejte se na proměny našich klientů. Najdete u nich videa, rozhovory s lékaři, fotografie před a po ošetření a dojmy a recenze klientů. Jak jsou spokojeni s výsledkem, co říkají na naše doktory a jak se jim líbili prostory Formé Clinic?': 'Podívejte se na proměny našich klientů. Najdete u nich videa, rozhovory s lékaři, fotografie před a po ošetření, dojmy a recenze klientů. Jak jsou spokojeni s výsledkem, co říkají na naše doktory a jak se jim líbily prostory Formé clinic?',
  'Proměny před a po našich klientů': 'Proměny před a po našich klientů',
  'další fotky před a po': 'další fotky před a po',
  'Pomáháme běžným lidem': 'Pomáháme každému',
  'Myšlenka nadačního fondu vznikla v říjnu roku 2017 s cílem poskytnout všestrannou chirurgickou a poradenskou pomoc a přiblížit činnost kliniky běžným lidem. Plastická chirurgie není jen pro bohaté a pro celebrity, ale pro všechny, kteří potřebují vyřešit nějaký problém. Finanční prostředky z výtěžků nadačních akcí, příspěvky partnerů i drobných dárců jsou věnovány především na osvětovou činnost, na nákup implantátů a na pomoc klientům, kteří operaci potřebují, ale nemůžou si ji dovolit.': 'Myšlenka nadačního fondu vznikla v říjnu roku 2017 s cílem poskytnout všestrannou chirurgickou a poradenskou pomoc a přiblížit činnost kliniky běžným lidem. Plastická chirurgie není jen pro bohaté a pro celebrity, ale pro všechny, kteří potřebují vyřešit nějaký problém. Finanční prostředky z výtěžků nadačních akcí, příspěvky partnerů i drobných dárců jsou věnovány především na osvětovou činnost, na nákup implantátů a na pomoc klientům, kteří operaci potřebují, ale nemůžou si ji dovolit.',
  'Pomáháme řešit problémy těm, kteří si to nemohou dovolit': 'Pomáháme řešit problémy těm, kteří si to nemohou dovolit.',
  'Plastická chirurgie je pro běžné lidi, nejen pro celebrity': 'Plastická chirurgie je pro každého',
  'Kombinace zákroků': 'Kombinace zákroků',
  'Díky jednomu operačnímu výkonu k nám budete mít jen jednu cestu a absolvujete jen jednu operaci a následné hojení. Spojení zákroků je rychlejší a efektnější.': 'Díky jednomu operačnímu výkonu k nám budete mít jen jednu cestu a absolvujete jen jednu operaci a následné hojení. Spojení zákroků je rychlejší a efektivnější.',
  'Tuto unikátní službu ocení třeba novopečené maminky, které se necítí po porodu ve vlastním těle dobře, touží po zeštíhlení či zpevnění problémových partií.': 'Tuto unikátní službu ocení třeba novopečené maminky, které se necítí po porodu ve vlastním těle dobře, touží po zeštíhlení či zpevnění problémových partií.',
  'people - meta title': 'Lidé',
  'Co má za sebou': 'Co má za sebou',
  'Jaké zákroky provádí': 'Jaké zákroky provádí',
  'Máte dotaz nebo se chcete domluvit na konzultaci?': 'Máte dotaz nebo se chcete domluvit na konzultaci?',
  'Kontakty': 'Kontakty',
  'Otevřeno': 'Otevřeno',
  'contact form - text': 'Rozhodnout se pro zákrok není jen tak. Máte otázky nebo potřebujete druhý názor? <b>Poraďte se s našimi chirurgy</b>. Domluvte si nezávaznou konzultaci <b>ZDARMA</b>. <br>Abychom společně našli vhodný termín konzultace co nejrychleji a probrali vaše případné dotazy, <b>napište nám prosím telefonní číslo a my vám rádi zavoláme.</b>.',
  'FORMÉ clinic': 'Formé clinic',
  'zavolejte mi': 'zavolejte mi',
  'Časté dotazy': 'Časté dotazy',
  'Kariéra': 'Kariéra',
  'Sledujte nás': 'Sledujte nás',
  '2013 - 2019 © Formé clinic. Všechna práva vyhrazena.': '2013 - 2019 © Formé clinic. Všechna práva vyhrazena.',
  'dalších <br /> {arg1} fotografií': 'dalších <br /> {arg1} fotografií',
  'dalších {arg1} fotografií': 'dalších {arg1} fotografií',
  'Cena zákroku': 'Cena zákroku',
  'Uváděná cena zákroků je orientační. Může se změnit ještě po prohlídce lékařem.': 'Cena je orientační, bude upřesněna po osobní konzultaci s lékařem.',
  'Co byste měli vědět o zákroku': 'Co byste měli vědět o zákroku',
  'Zákrok provádí': 'Zákrok provádí',
  'Zákrok se platí předem.': 'Zákrok se platí předem.',
  'Vlastní předoperační vyšetření je velké plus, urychlý tak ranní proces na klinice.': 'Vlastní předoperační vyšetření je velké plus, není však podmínkou. Lze jej také zajistit na naší klinice v den operace.',
  'Časté kombinace': 'Časté kombinace',
  'Operaci poprsí a liposukce': 'Operaci poprsí a liposukce',
  'Liposukce více partií najednou': 'Liposukce více partií najednou',
  'Modelace prsou a operace břicha': 'Modelace prsou a operace břicha',
  'více o balíčcích': 'více o balíčcích',
  'Ten nejlepší odpočinek i po zákroku. Na naší klinice máme k dispozici moderní apartmány pro klienty z daleka i pro rodinné příslušníky. Můžete tak být nablízku našim lékařům i zdravotnickému personálu i po propuštění z kliniky.': 'Ten nejlepší odpočinek i po zákroku. Na naší klinice máme k dispozici moderní apartmány pro klienty z daleka i pro rodinné příslušníky. Můžete tak být nablízku našim lékařům a zdravotnickému personálu i po propuštění z kliniky.',
  'Career - meta title': 'Kariéra u nás',
  'career - info': 'Formé clinic je plastická a estetická klinika s osmiletou historií. Náš personál sbíral zkušenosti na tuzemských i zahraničních klinikách. Spojuje nás jedno: Do naší práce dáváme především srdce. Dbáme na to, aby se u nás cítili dobře nejen klienti, ale také náš personál.<br/><br/>Naši klienti se k nám velmi často vracejí za “svým” lékařem a naše služby s velkou oblibou doporučují dál.<br/><br/>',
  'Seznam aktuálních volných pozic:': 'Seznam aktuálních volných pozic:',
  'Zašli nám svůj strukturovaný životopis': 'Zašlete nám svůj strukturovaný životopis',
  'faq - meta title': 'FAQ - Otázky a odpovědi',
  'faq - meta desc': 'faq - meta desc',
  'Všichni zaměstnanci': 'Všichni zaměstnanci',
  'Online konzultace': 'Online <br/> konzultace',
  'Přidat fotku': 'Přidat fotku',
  'Jméno a příjmení': 'Jméno a příjmení',
  'Email': 'E-mail',
  'Dotaz': 'Dotaz',
  'Proč si vybrat nás?': 'Nadstandard je náš standard',
  'Jsme výjimeční naším osobním přístupem. Nechodí k nám nechodí jen celebrity, jsme klinika pro běžné lidi, s rodinnou atmosférou. Máme all inclusive balíčky. Nejsme továrna na nová těla, ale specializovaná klinika s pěti lůžky, kde je dost času na nadstandard.': 'Jsme výjimeční naším osobním přístupem. Jsme klinika pro každého s rodinnou atmosférou. Nabízíme all-inclusive balíčky. Nejsme továrna na nová těla, ale specializovaná klinika s apartmány, kde je dost času na nadstandard.',
  'Konzultace je zdarma a nezávazná': 'Pomohli jsme již tisícům klientů',
  'Vše pod jednou střechou': 'Vše pod jednou střechou',
  'Vybavení': 'Vybavení',
  'Veškerá základní vyšetření potřebná k zákrokům zařídíme na naší klinice. Bez starostí provedeme EKG i krevní testy': 'Veškerá základní vyšetření potřebná k zákrokům zařídíme na naší klinice. Bez starostí provedeme EKG i krevní testy.',
  'Strava': 'Strava',
  'Během rekonvalescence na klinice podáváme kvalitní a pestrou BIO stravu, jejíž složení je přesně uzpůsobeno pro co nejlepší regeneraci po zákroku. Nezapomínáme ani na alergiky, vegetariány a vegany.': 'Během rekonvalescence na klinice podáváme kvalitní a pestrou BIO stravu, jejíž složení je přesně uzpůsobeno pro co nejlepší regeneraci po zákroku. Nezapomínáme ani na alergiky, vegetariány a vegany.',
  'Zábava': 'Zábava',
  'Pro pohodlný odpočinek a regeneraci. Během hospitalizace máte k dispozici tablet s wifi připojením nebo dvd přehrávač s filmy mnoha žánrů. U nás se nudit nebudete.': 'Pro pohodlný odpočinek a regeneraci. Během hospitalizace máte k dispozici tablet s wifi připojením nebo DVD přehrávač s filmy mnoha žánrů. U nás se nudit nebudete.',
  'Personál': 'Personál',
  'Můžeme se chlubit tím nejlepším personálem, za kterým stojí velký tým plastických chirurgů a anesteziologů se zkušenostmi z celého světa. Náš ochotný personál včetně klient koordinátorů ovládá dva světové jazyky.': 'Můžeme se chlubit tím nejlepším personálem, za kterým stojí velký tým plastických chirurgů a anesteziologů se zkušenostmi z celého světa. Náš ochotný personál včetně klient koordinátorů ovládá dva světové jazyky.',
  'Taxi': 'Taxi',
  'Materiál': 'Materiál',
  'Pracujeme jen s těmi nejlepšími materiály renomovaných značek v nejvyšší dostupné kvalitě. Používáme pouze prvotřídní implantáty, šití i nejlépe hodnocené léky pro šetrnou anestezii. Pohodlné pooperační kompresní prádlo je samozřejmostí.': 'Pracujeme jen s těmi nejlepšími materiály renomovaných značek v nejvyšší dostupné kvalitě. Používáme pouze prvotřídní implantáty, šití i nejlépe hodnocené léky pro šetrnou anestezii. Pohodlné pooperační kompresní prádlo je samozřejmostí.',
  'All inclusive': 'All-inclusive',
  'Každý zákrok je all inclusive. V jeho ceně tak máte anestezii, hospitalizaci v moderních pokojích, kompresní prádlo, pooperační kontroly i balíček léků pro správnou péči po zákroku. Po propuštění jsou vám naši lékaři kdykoliv k dispozici na telefonu i na emailu.': 'Každý zákrok je all inclusive. V jeho ceně tak máte anestezii, hospitalizaci v moderních pokojích, kompresní prádlo, pooperační kontroly i balíček léků pro správnou péči po zákroku. Po propuštění jsou vám naši lékaři kdykoliv k dispozici na telefonu i na emailu.',
  'Pojištění': 'Pojištění',
  'Jsme pojištěni u Kooperativa, můžete být bez starostí. Naše klinika má sjednané nejspolehlivější pojištění.': 'Jsme pojištěni u Kooperativa, můžete být bez starostí. Naše klinika má sjednané nejspolehlivější pojištění.',
  'Jak to u nás vypadá?': 'Jak to u nás vypadá?',
  'Apartmány v budově kliniky': 'Ručíme vám za kvalitu',
  'Klinika pro lidi': 'Měníme vzhled, měníme život',
  'Na naší klinice máme k dispozici moderní apartmány pro klienty z daleka i pro rodinné příslušníky.': 'Používáme nejmodernější přístroje a postupy, nejšetrnější anestezii a certifikované materiály s garancí vysoké kvality.',
  'Přes 70 tisíc spokojených klientů': 'Konzultace je zdarma a nezávazná',
  'Více o naší klinice': 'Více o naší klinice',
  'Naši lékaři': 'Máme tým špičkových lékařů',
  'Celý tým': 'Celý tým',
  'Co máme za sebou': 'Vážíme si vaší důvěry',
  'operací celkově': 'neinvazivních zákroků',
  'zvětšení prsou': 'operací ',
  'spokojených klientů': 'spokojených klientů',
  'kombinovaných operací': 'kombinovaných operací',
  'Ceny najdete v detailu jednotlivého zákroku.': 'Ceny najdete v detailu jednotlivého zákroku.',
  'Co říkají naši klienti': 'Co říkají naši klienti',
  'více recenzí': 'více recenzí',
  'Recenze': 'Recenze',
  'Tady bude text o tom, jak se klienti do Formé Clinic dostanou. Možná info o MHD a hlavně že můžou parkovat v garážích zdarma.': 'Najdete nás 10 minut tramvají od Hlavního nádraží v Praze. Z tramvajové zastávky Hlavní nádraží k nám jedou tramvaje 5, 9, 15 a 26 na zastávku Olšanské náměstí a odtud jen 5 minut pěšky směrem k parku Parukářka. Autem můžete zaparkovat u nás v garážích.',
  'Jak se k nám dostanete?': 'Jak se k nám dostanete?',
  'Email je povinný, prosím doplňte': 'Email je povinný, prosím doplňte',
  'Děkujeme!': 'Děkujeme!',
  'Více o zákroku': 'Více o zákroku',
  'Recenze na': 'Recenze na',
  '/career': '/kariera',
  'Vlastní předoperační vyšetření je velké plus, urychlí tak ranní proces na klinice.': 'Vlastní předoperační vyšetření je velké plus, není však podmínkou. Lze jej také zajistit na naší klinice v den operace.',
  'Jméno je povinné, prosím doplňte': 'Jméno je povinné, prosím doplňte',
  'Dotaz je povinný, prosím doplňte': 'Dotaz je povinný, prosím doplňte',
  'Email není validní, prosím doplňte': 'Email není validní, prosím doplňte',
  'další <br /> {arg1} fotografie': 'další <br /> {arg1} fotografie',
  'smazat': 'smazat',
  'Došlo k chybě při odesílání formuláře, zkuste to prosím později.': 'Došlo k chybě při odesílání formuláře, zkuste to prosím později.',
  'V pořádku': 'V pořádku',
  'Více informací': 'Více informací',
  '/changes': '/promeny',
  '/foundation': '/nadace',
  '/faq': '/caste-dotazy',
  '/team': '/nas-tym',
  '/references': '/reference',
  '/contact': '/kontakt',
  'Najdete nás 10 minut tramvají od Hlavního nádraží v Praze. Z tramvajové zastávky Hlavní nádraží k nám jedou tramvaje 5, 9, 15 a 26 na zastávku Olšanské náměstí a odtud je to 5 minut směr park Parukářka. Autem můžete parkovat u nás v garážích.': 'Najdete nás 10 minut tramvají od Hlavního nádraží v Praze. Z tramvajové zastávky Hlavní nádraží k nám jedou tramvaje 5, 9, 15 a 26 na zastávku Olšanské náměstí a odtud jen 5 minut pěšky směrem k parku Parukářka. Autem můžete zaparkovat u nás v garážích.',
  'Všechny zákroky': 'Všechny zákroky',
  'další proměny': 'další proměny',
  'Odesílám...': 'Odesílám...',
  'Odeslat': 'Odeslat',
  'Interiér Formé clinic': 'Interiér Formé clinic',
  'Další volné pozice:': 'Další volné pozice:',
  'Primář Formé Clinic': 'Primář Formé clinic',
  'Plastický chirurg': 'Plastický chirurg',
  'Plastický chirurg Formé Clinic': 'Plastický chirurg ',
  'procedures - meta desc': 'Zde najdete seznam všech zákroků, které provádíme. Konzultace jsou bezplatné a nezávazné. Přijďte navštívit naši kliniku a zeptejte se našich odborníků na cokoliv. Vždy si na vás rádi udělají čas. Orientační cenu najdete vždy u konkrétního zákroku.',
  'contact - meta desc': 'Zavolejte nebo nám napište a my vám zavoláme zpět. Jsme tu pro vás každý den.',
  'Mohlo by vás zajímat': 'Mohlo by vás zajímat',
  'Vybíráme z vašich příběhů a pomáháme uskutečnit váš sen': 'Vybíráme z vašich příběhů a pomáháme uskutečnit váš sen',
  'Zde najdete seznam všech zákroků, které provádíme. Konzultujeme bezplatně a nezávazně. Přijďte navštívit naši kliniku a zeptejte se našich odborníků na cokoliv. Vždy si na vás rádi udělají čas. Orientační cenu najdete vždy u konkrétníko zákroku.': 'Zde najdete seznam všech zákroků, které provádíme. Konzultace jsou bezplatné a nezávazné. Přijďte navštívit naši kliniku a zeptejte se našich odborníků na cokoliv. Vždy si na vás rádi udělají čas. Orientační cenu najdete vždy u konkrétníko zákroku.',
  'Spojte několik zákroků do jednoho operačního výkonu a ušetřete nejen finance, ale i váš čas.': 'Spojte několik zákroků do jednoho operačního výkonu a ušetřete nejen finance, ale i váš čas.',
  'Lékař s vámi probere všechna vaše trápení a navrhne možné kombinace, které jsou pro vás nejvhodnější a nejlépe vyhovují vašemu zdravotnímu stavu.': 'Lékař s vámi probere všechna vaše trápení a navrhne možné kombinace, které jsou pro vás nejvhodnější a nejlépe vyhovují vašemu zdravotnímu stavu.',
  'team - intro': 'Náš tým se skládá ze skvělých profesionálů ve svém oboru. Postará se o vaše pohodlí, zdraví i bezpečí. Pokud se nám svěříte do péče, můžeme vám zaručit, že v celém procesu nebudete sami. Pomůžeme vám s rozhodováním, plánováním i samotným zákrokem. Zjistíme, co je pro vás to nejlepší a individuálním přístupem zaručíme, že služby, které vám poskytneme, budou té nejvyšší kvality již od prvního podání ruky až k závěrečnému rozloučení.<br />  <br />  Těšíme se na vás.',
  'Zavoláme vám': 'Zavoláme vám',
  'Zadejte telefonní číslo v mezinárodním formátu a my se vám ozveme.': 'Zadejte telefonní číslo v mezinárodním formátu a my se vám ozveme.',
  'Staňte se součástí Formé rodiny. Hledáme lidi, kteří nám pomohou měnit osudy našich klientů.': 'Společně měníme lidem životy. Poskytujeme tu nejlepší péči všem, kteří si přišli splnit sny, najít ztracené sebevědomí, nebo sebe samotné. Přidáš se?',
  'Career - meta desc': 'Společně měníme lidem životy. Poskytujeme tu nejlepší péči všem, kteří si přišli splnit sny, najít ztracené sebevědomí, nebo sebe samotné. Přidáš se?',
  'contact form - homepage text': 'Rozhodnout se pro zákrok není jen tak. Máte otázky nebo potřebujete druhý názor? <b>Poraďte se s našimi chirurgy</b>. Domluvte si nezávaznou konzultaci <b>ZDARMA</b>. <br>Abychom společně našli vhodný termín konzultace co nejrychleji a probrali vaše případné dotazy, <b>napište nám prosím telefonní číslo a my vám rádi zavoláme.</b>',
  'Přijďte se podívat. Dáte si kávu, prohlédnete si kliniku a zeptáte se lékaře na všechno, co Vás zajímá. Jsme stále online. Poradíme, i když to k nám máte daleko. S našimi lékaři si můžete povídat i z pohodlí vašeho domova.': 'Přijďte se podívat. Dáte si kávu, prohlédnete si kliniku a zeptáte se lékaře na všechno, co vás zajímá. Jsme stále online. Poradíme, i když to k nám máte daleko. S našim personálem jste v kontaktu i z pohodlí vašeho domova.',
  'Na vaše pohodlí myslíme i po operaci. Dopravu domů nemusíte řešit. Po zákrocích v celkové i lokální anestezii vás v rámci Prahy odvezeme zdarma.': 'Na vaše pohodlí myslíme i po operaci. Dopravu domů nemusíte řešit. Po zákrocích v celkové i lokální anestezii vás v rámci Prahy odvezeme zdarma.',
  'Používáme jen ověřené chirurgické postupy, za které můžeme ručit. Proto ať už Vás k nám přivedou na plastiku vrozené dispozice, věk, nebo úraz, jsme připraveni Vám pomoci.': 'Chcete být krásnější a sebevědomější? Pak jste na správném místě. Čeká na vás tým předních a uznávaných plastických chirurgů, anesteziologů a zdravotních sester, kteří se o vás postarají a provedou vás vaší cestou k vysněné proměně.',
  'Jsme výjimeční osobním přístupem. Nechodí k nám nechodí jen celebrity, jsme klinika pro běžné lidi, s rodinnou atmosférou. Nejsme továrna na nová těla, ale specializovaná klinika s pěti lůžky, kde je dost času na nadstandard.': 'Jsme výjimeční osobním přístupem a nadstandardní péčí. Naším cílem je dosáhnout co nejpřirozenějšího vzhledu s ohledem na vaši osobnost a vaše přání. Vaše maximální bezpečnost, šetrnost a pohodlí je pro nás nejvyšší prioritou.',
  'Přijďte navštívit naši kliniku a zeptejte se našich odborníků na cokoliv. Vždy si na vás rádi udělají čas.': 'Změnili jsme lidem životy, pomohli splnit jejich sny. Nejvyšší hodnocení v nezávislých recenzích jsou toho důkazem.',
  'kteří se obrátili na naši kliniku a užívají si výsledky naší práce. A že je něco jinak, neuvidíte jen Vy, ale také Vaše okolí.': 'Rozhodnout se pro zákrok není jen tak. Přijďte na nezávaznou konzultaci a získejte dokonalou představu o dostupném a konkrétním řešení. Lékař vám doporučí vždy to nejlepší.',
  'Náš tým se skládá ze skvělých profesionálů ve svém oboru. Postará se o Vaše pohodlí, zdraví i bezpečí. Pokud se chcete svěřit do rukou lidem, kteří mají rádi svou práci a záleží jim na tom, co dělají, jste na správném místě.': 'Budete v těch nejlepších rukou předních a uznávaných plastických chirurgů s letitými zkušenostmi v ČR i zahraničí. Vaše spokojenost, zdraví a bezpečí je pro naše lékaře na prvním místě.',
  'Obratem se vám ozveme a domluvíme se na nezávaznou konzultaci.': 'Obratem se vám ozveme a domluvíme se na nezávaznou konzultaci.',
  'Děkujeme,<br />ozveme se vám obratem.': 'Děkujeme,<br />ozveme se vám obratem.',
  'EU FOOTER - cookies': 'Tento web používá cookies, abychom ho pro vás mohli neustále zlepšovat. V případě, že nechcete, aby se na těchto stránkách cookies načítaly, můžete si je vymazat a vypnout v nastavení prohlížeče. ',
  'Zavolám vám': 'Zavolám vám',
  'Plastická a estetická chirurgie v Praze · FORMÉ clinic': 'Plastická a estetická chirurgie v Praze · FORMÉ clinic',
  'GDPR': 'GDPR',
  'Všechna práva vyhrazena.': 'Všechna práva vyhrazena.',
  'Zadejte telefonní číslo v mezinárodním formátu a ozvu se vám.': 'Zadejte telefonní číslo v mezinárodním formátu a ozvu se vám.',
  'Zobrazit všechny fotografie': 'Zobrazit všechny fotografie',
  'Kontakt na apartmány': 'Kontakt na apartmány',
  'Pro rezervaci apartmánu <br />nás kontaktujte na:': 'Pro rezervaci apartmánu <br />nás kontaktujte na:',
  'Apartmány': 'Apartmány',
  '/apartments': '/apartmany',
  'Koronavirus': 'Koronavirus',
  'Plastická chirurgie': 'Plastická chirurgie',
  'Ortopedie': 'Ortopedie',
  'nové': 'nové',
  'nově': 'nově',
  'Díky špičkovému vybavení moderních operačních sálů a profesionálnímu specialozovanému personálu vám Formé Clinic poskytne tu nejlepší péči v oblasti ortopedie. Zabýváme léčbou poruch pohybového aparátu dle nejnovějších medicínských poznatků.': 'Svěřte se do rukou uznávaných specialistů v oblasti ortopedie. Zabýváme se léčbou poruch pohybového aparátu dle nejnovějších medicínských poznatků. Díky špičkovému vybavení moderních operačních sálů vám poskytneme tu nejlepší péči.',
  'Ortopedické zákroky': 'Ortopedické zákroky',
  'Nově otevřeno': 'Nově otevřeno',
  'Estetická plastika': 'Estetická plastika',
  'Nové': 'Nové',
  'Provádíme kompletní škálu estetických plastických operací - zvětšení a modelaci prsou, liposukci, plastiku nosu a další. Ať už vás k nám přivedou vrozené dispozice, věk, nebo úraz, jsme připraveni vám pomoci.': 'Provádíme kompletní škálu estetických plastických operací - zvětšení a modelaci prsou, liposukci, plastiku nosu a další. Ať už vás k nám přivedou vrozené dispozice, věk, nebo úraz, jsme připraveni vám pomoci.',
  'zákroky estetické plastiky': 'zákroky estetické plastiky',
  'ortopedické zákroky': 'ortopedické zákroky',
  'Klient koordinátor pro Ortopedii': 'Klient koordinátor pro Ortopedii',
  'Telefon': 'Telefon',
  'Při provedení všech zákroků a ošetření dbáme na maximální šetrnost a klademe důraz na bezpečnost pro vaše zdraví.': 'Při provedení všech zákroků a ošetření dbáme na maximální šetrnost a klademe důraz na bezpečnost pro vaše zdraví.',
  'Používáme nejmodernější přístroje a postupy s garancí vysoké kvality odborné péče profesionálů uznávaných u nás i v zahraničí. ': 'Používáme nejmodernější přístroje a postupy s garancí vysoké kvality odborné péče profesionálů uznávaných u nás i v zahraničí. ',
  'Používáme nejmodernější přístroje a postupy s garancí vysoké kvality odborné péče profesionálů uznávaných u nás i v zahraničí.': 'Používáme nejmodernější přístroje a postupy s garancí vysoké kvality odborné péče profesionálů uznávaných u nás i v zahraničí.',
  'Cena je orientační a může se lišit na základě vašich konkrétních potřeb a rozsahu zákroku. Upřesněna bude po osobní konzultaci s lékařem.': 'Cena je orientační a může se lišit na základě vašich konkrétních potřeb a rozsahu zákroku. Upřesněna bude po osobní konzultaci s lékařem.',
  'Aktuální informace': 'Aktuální informace',
  'Máme pro vás otevřeno!': 'Máme pro vás otevřeno!',
  'Dodržujeme pravidlo 3R.': 'Dodržujeme pravidlo 3R.',
  'Covid-19 info': 'Covid-19 info',
  'Vaše bezpečnost je naše priorita.': 'Vaše bezpečnost je naše priorita.',
  'Váš tým Formé clinic': 'Váš tým Formé clinic',
  'Velice nás těší Váš zájem o osobní konzultaci ve Formé clinic. Nejpozději do dvou pracovních dnů se Vám ozveme a domluvíme společně termín konzultace, který Vám bude nejvíce vyhovovat. Těšíme se na Vás.': 'Velice nás těší Váš zájem o osobní konzultaci ve Formé clinic. Nejpozději do dvou pracovních dnů se Vám ozveme a domluvíme společně termín konzultace, který Vám bude nejvíce vyhovovat. Těšíme se na Vás.',
  'Formé clinic je zdravotnické zařízení. Návštěva kliniky a veškeré zákroky jsou <b>v souladu s aktuálními vládními opatřeními COVID-19 platnými v České republice.</b>': 'Jsme zdravotnické zařízení. Návštěva kliniky a veškeré zákroky jsou <b>v souladu s aktuálními vládními opatřeními COVID-19 platnými v České republice.</b>',
  'Pro rychlejší komunikaci nám prosím napište telefonní číslo.': 'Zadejte telefonní číslo v mezinárodním formátu (+420...).',
  '<b>Máme pro vás stále otevřeno.</b> Formé clinic je zdravotnické zařízení. Návštěva kliniky a veškeré zákroky jsou <b>v souladu s aktuálními vládními opatřeními COVID-19 platnými v České republice.</b> V případě cesty z jiného okresu vám vystavíme <b>potvrzení o návštěvě lékaře.</b>': '<b>Máme pro vás stále otevřeno.</b> Formé clinic je zdravotnické zařízení. Návštěva kliniky a veškeré zákroky jsou <b>v souladu s aktuálními vládními opatřeními COVID-19 platnými v České republice.</b>',
  '           Formé clinic je zdravotnické zařízení. Návštěva kliniky a veškeré zákroky jsou <b>v souladu s aktuálními vládními opatřeními COVID-19 platnými v České republice.</b>': '---empty---',
  'Akceptujeme tyto benefitní karty': 'Akceptujeme tyto benefitní karty',
  'Nově nabízíme možnost rozložit si cenu zákroku až do 24 měsíčních plateb bez navýšení.': 'Nabízíme možnost rozložit si cenu zákroku až do 24 měsíčních plateb bez navýšení.',
  'Dopřejte si změnu hned a plaťte pak!': 'Dopřejte si změnu hned a&nbsp;plaťte pak!',
  'Plastická a estetická medicína': 'Plastická a estetická medicína',
  'Provádíme kompletní škálu estetických plastických operací. Ať už vás k nám přivedou vrozené dispozice, touha po krásnějším vzhledu nebo úraz, jsme připraveni vám pomoci.': 'Provádíme kompletní škálu estetických plastických operací. Ať už vás k nám přivedou vrozené dispozice, touha po krásnějším vzhledu nebo úraz, jsme připraveni vám pomoci.',
  'Dopřejte si změnu hned a plaťte pak. Nově nabízíme možnost rozložit si cenu zákroku až do 24 měsíčních plateb bez navýšení.': 'Dopřejte si změnu hned a plaťte pak. Nově nabízíme možnost rozložit si cenu zákroku až do 24 měsíčních plateb bez navýšení.',
  'FORMÉ clinic a kontakt': 'FORMÉ clinic a kontakt',
  'plastických operací': 'plastických operací',
  'Odesláním formuláře souhlasíte se': 'Odesláním formuláře souhlasíte se',
  'zásadami zpracování a ochrany osobních údajů.': 'zásadami zpracování a ochrany osobních údajů.',
  'Telefon je povinný, prosím doplňte': 'Telefon je povinný, prosím doplňte',
  'Všechny otázky': 'Všechny otázky',
  'Trvalá epilace': 'Trvalá epilace',
  'Zbavíme vás nežádoucího ochlupení jednou pro vždy. Jako jediní v České republice disponujeme revolučním lékařským diodovým laserem OPT LED Excelsior 3.0 s unikátní 3D hlavicí, který nám umožňuje odstranit i ty nejjemnější a nejsvětlejší chloupky s téměř 100% účinností.': 'Zbavíme vás nežádoucího ochlupení jednou pro vždy. Jako jediní v České republice disponujeme revolučním lékařským diodovým laserem OPT LED Excelsior 3.0 s unikátní 3D hlavicí, který nám umožňuje odstranit i ty nejjemnější a nejsvětlejší chloupky s téměř 100% účinností.',
  'Chci se objednat': 'Chci se objednat',
  'Dermatologie': 'Dermatologie',
  'Fotografie před a po': 'Fotografie před a po',
  'Storno podmínky': 'Storno podmínky',
  'Zdravotní pohotovost': 'Zdravotní pohotovost',}