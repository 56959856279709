import React from "react"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import Row from "./Row"
import Col from "./Col"
import Gap from "./Gap"
import Title from "./Title"
import Text from "./Text"
import VH from "./VH"
import WaveImage from "./WaveImage"
import T from "../lib/intl/T"
import { up } from "../lib/styles"

const data = [
  {
    title: "Vybavení",
    text: "Veškerá základní vyšetření potřebná k zákrokům zařídíme na naší klinice. Bez starostí provedeme EKG i krevní testy",
  },
  {
    title: "Strava",
    text: "Během rekonvalescence na klinice podáváme kvalitní a pestrou BIO stravu, jejíž složení je přesně uzpůsobeno pro co nejlepší regeneraci po zákroku. Nezapomínáme ani na alergiky, vegetariány a vegany.",
  },
  {
    title: "Zábava",
    text: "Pro pohodlný odpočinek a regeneraci. Během hospitalizace máte k dispozici tablet s wifi připojením nebo dvd přehrávač s filmy mnoha žánrů. U nás se nudit nebudete.",
  },
  {
    title: "Personál",
    text: "Můžeme se chlubit tím nejlepším personálem, za kterým stojí velký tým plastických chirurgů a anesteziologů se zkušenostmi z celého světa. Náš ochotný personál včetně klient koordinátorů ovládá dva světové jazyky.",
  },
  {
    title: "Taxi",
    text: "Na vaše pohodlí myslíme i po operaci. Dopravu domů nemusíte řešit. Po zákrocích v celkové i lokální anestezii vás v rámci Prahy odvezeme zdarma.",
  },
  {
    title: "Materiál",
    text: "Pracujeme jen s těmi nejlepšími materiály renomovaných značek v nejvyšší dostupné kvalitě. Používáme pouze prvotřídní implantáty, šití i nejlépe hodnocené léky pro šetrnou anestezii. Pohodlné pooperační kompresní prádlo je samozřejmostí.",
  },
  {
    title: "All inclusive",
    text: "Každý zákrok je all inclusive. V jeho ceně tak máte anestezii, hospitalizaci v moderních pokojích, kompresní prádlo, pooperační kontroly i balíček léků pro správnou péči po zákroku. Po propuštění jsou vám naši lékaři kdykoliv k dispozici na telefonu i na emailu.",
  },
  {
    title: "Pojištění",
    text: "Jsme pojištěni u Kooperativa, můžete být bez starostí. Naše klinika má sjednané nejspolehlivější pojištění.",
  },
]

const AboutImageWrapper = styled.div`
  position: relative;
`

const getBp = wide => (wide ? 1130 : 1050)

const position = (top, left) => css`
  top: ${top}px;
  left: ${left}px;
`

const Info = styled.div`
  ${({ theme, index, wide }) => css`
    max-width: ${wide ? "500px" : "420px"};
    position: relative;
    ${up(getBp(wide))} {
      width: ${wide ? "500px" : "420px"};
      position: absolute;
      ${index === 0 && position(21, 373)}
      ${index === 1 && position(167, 538)} 
      ${index === 2 && position(343, 473)} 
      ${index === 3 && position(556, 412)} 
      ${index === 4 && position(wide ? 730 : 748, 487)} 
      ${index === 5 && position(889, 584)} 
      ${index === 6 && position(1081, 498)} 
      ${index === 7 && position(1293, 376)}
    }
  `}
`

const AboutSection = ({ image, mobileImages, t, wide }) => (
  <>
    <Row>
      <Col alignItems="flex-start">
        <WaveImage />
        <Gap gap="24px" />
        <Title.BiggerTitle>
          <T>Vše pod jednou střechou</T>
        </Title.BiggerTitle>
        <Gap gap="40px" />
        <AboutImageWrapper>
          {data.map(({ title, text }, key) => (
            <Info key={key} index={key} wide={wide}>
              <Col>
                <Row alignItems="center">
                  <Row showBelow={getBp(wide) - 1}>
                    <GatsbyImage
                      image={mobileImages[key].image.gatsbyImageData}
                      alt={t(title)}
                    />
                    <Gap gap="16px" />
                  </Row>
                  <Title.SmallerTitle>
                    <T>{title}</T>
                  </Title.SmallerTitle>
                </Row>
                <Gap gap="8px" mobileGap="16px" />
                <Text responsive>
                  <T>{text}</T>
                </Text>
                <Gap gap="40px" />
              </Col>
            </Info>
          ))}
          <VH showAfter={getBp(wide)}>
            <GatsbyImage image={image.image.gatsbyImageData} />
          </VH>
        </AboutImageWrapper>
      </Col>
    </Row>
  </>
)

AboutSection.defaultProps = {}

AboutSection.propTypes = {
  image: PropTypes.object.isRequired,
  mobileImages: PropTypes.array.isRequired,
}

export default AboutSection
