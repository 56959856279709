import React from "react"
import styled, { css } from "styled-components"
import { addResponsivity } from "../lib/styles"

// Responsivity helper
const RH = styled.div`
  ${addResponsivity()}
`

export default RH
