import React, { useEffect } from "react"

class EstheticonWidget extends React.Component {
  componentDidMount() {
    if (typeof window !== "undefined") {
      const s = document.createElement("script")
      s.type = "text/javascript"
      s.async = true
      s.innerHTML =
        "(function (w,d,s,o,f,js,fjs,h,t) { w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments) }; js = d.createElement(s); fjs = d.getElementsByTagName(s)[0]; js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs); h = d.getElementsByTagName('head')[0]; t = d.createElement('link'); t.rel = 'stylesheet'; t.setAttribute('type', 'text/css'); t.href = 'https://www.estheticon.cz/10019966/css/gen/widget_main.css'; h.appendChild(t); }(window, document, 'script', 'ynsWidget', 'https://www.estheticon.cz/10019966/build/js/main_widget.js')); ynsWidget({ hostname: 'https://www.estheticon.cz', hostnameStatic: 'https://static.estheticon.cz', projectId: 200, companyId: 351467, slotId: 'ynsWidgetSlot', h: 'c9f2dd6f674152f99572162ae97537b2', }); "
      document.body.appendChild(s)
      return () => {
        document.body.removeChild(s)
      }
    }
  }

  render() {
    return (
      <>
        <div id="ynsWidgetSlot">
          <div className="ynsWidget-load-screen">
            <svg
              className="yns-spinner"
              width="40px"
              height="40px"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              preserveAspectRatio="xMidYMid"
            >
              <rect
                className="yns-spinner__rect"
                x="0"
                y="0"
                width="100"
                height="100"
                fill="none"
              />
              <circle
                className="yns-spinner__circle"
                cx="50"
                cy="50"
                r="40"
                stroke="#19b5bc"
                fill="none"
                strokeWidth="6"
                strokeLinecap="round"
              />
            </svg>
            <a
              className="yns-widget__btn"
              href="https://www.estheticon.cz/chirurgove/forme-clinic"
              rel="follow"
            >
              FORMÉ Clinic
            </a>
          </div>
        </div>
      </>
    )
  }
}

export default EstheticonWidget
